.twitter-typeahead {
    width: 100%;
}
.tt-menu {
    background-color: #ffffff;
    padding: .5rem 0;
    border-radius: 10px;
    box-shadow: 0 15px 40px 0 rgba(0,0,0,.15);
    width: 100%;
    z-index: 1500 !important;
}
.tt-suggestion {
    padding: 0 1rem;
}
.tt-suggestion:hover,
.tt-cursor {
    cursor: pointer;
    background-color: var(--color-light-grey);
}