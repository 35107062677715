.town-teaser {
    display: block;
    margin:.625rem;
    position: relative;
    text-align: center;
    font-size:1rem;
    border-radius: 10px;
    box-shadow: 0 15px 20px 0 rgba(0,0,0,0.15);
    overflow: hidden;
    @media screen and (min-width: 768px) {
        margin:1rem;
    }
}
.town-teaser__body {
    background: #fff;
    padding:.75rem;

    @media screen and (min-width: 768px) {
        padding:1.125rem;
    }
}
.town-teaser a {
    display: inline-block;
    line-height: 28px;
    /*padding-left: 1.75rem;*/
    position: relative;
    color:inherit;
    text-decoration: none;
}
/*.town-teaser a:before {*/
/*    position: absolute;*/
/*    left:0;*/
/*    top:-.375rem;*/
/*    font-size:1.375rem;*/
/*    font-family: iconfont;*/
/*    content:var(--icon-poi-outline);*/
/*}*/