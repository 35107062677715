.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .7);
    z-index: 999;
    text-align: center;
}