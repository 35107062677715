.objekt-overview {
    position: relative;
    padding:2rem 0;
}





/* filter */
.objekt-filter {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #C7C7C7;
    margin-bottom: 1.5rem;
}
.objekt-filter__top {
    padding:1rem;
    @media screen and (min-width: 768px) {
        padding:1.5rem;
    }
}
.objekt-filter .objekt-filter__top .sort-block {
    /*margin-right: 2rem;*/
    position: relative;
}

.objekt-filter .objekt-filter__top .sort-block .form-control-nostyle {
    background-color: #ffffff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-weight: var(--font-default-bold-weight);
    padding-right: 25px;
    padding-left: 5px;
    font-size: 17px;
}
.objekt-filter .objekt-filter__top .sort-block .icon-chevron {
    font-size: .75rem;
    transform: rotate(90deg);
    margin-left: .5rem;
    position: absolute;
    right: 7px;
    top: 0.65rem;
    pointer-events: none;
}

.objekt-filter .icon-zentrum,
.objekt-filter .icon-icon_sortieren,
.objekt-filter .icon-icon_filter {
    font-size: 18px !important;
    top: 3px !important;
    position: relative !important;
}

.objekt-filter .icon-icon_filter {
    margin-right: .5rem;
}

.detail-filters {
    padding:1rem;
    border-top: 1px solid #C7C7C7;
    @media screen and (min-width: 768px) {
        padding:.25rem 1.5rem 2rem 1.5rem;
    }
}
.detail-filters__heading {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    margin:.5rem 0;
}
@supports (font-variation-settings: "wdth" 315) {
    .detail-filters__heading {
        font-family: var(--font-default-vf);
    }
}
.objekt-filter__collapse,
.objekt-filter__map {
    position: relative;
}
.objekt-filter__collapse {
    margin-right: .5rem;
}
.objekt-filter__map>.icon,
.objekt-filter__collapse>.icon {
    font-size:.75rem;
    transform: rotate(-90deg);
    position: relative;
    top:0;
    margin-left: .5rem;
}
.objekt-filter__map.collapsed>.icon,
.objekt-filter__collapse.collapsed>.icon {
    transform: rotate(90deg);
}

@media screen and (min-width: 768px) {
    .objekt-filter__map {
        display: inline-block;
        position: relative;
        padding-right:5rem;
    }
    a.objekt-filter__map .fake-swipe.fake-swipe {
        position: absolute;
        right:0;
        top:-.5rem;
    }
}