.nav-tabs--radio-style {
    border:0;
    display: inline-block;
}
.nav-tabs--radio-style .nav-item {
    display: inline-block;
}
.nav-tabs--radio-style .nav-item.show .nav-link, .nav-tabs--radio-style .nav-link, .nav-tabs--radio-style .nav-link.active {
    display: inline-block;
    position: relative;
    color: var(--color-text-default);
    border:0;
    padding:.125rem 0 .125rem 1.75rem;
    margin-right:1.5rem;
}
.nav-tabs--radio-style .nav-link:focus, .nav-tabs--radio-style .nav-link:hover {
    border:0;
}

.nav-tabs--radio-style .nav-link:before{
    content:'';
    position: absolute;
    left:0;
    top:8px;
    display: inline-block;
    border: 1px solid var(--color-default);
    background: #ffffff;
    width: 17px;
    height: 17px;
    border-radius: 50%;

    /*@media screen and (min-width: 768px) {*/
    /*    top:6px;*/
    /*}*/
}

.nav-tabs--radio-style .nav-item.show .nav-link:before, .nav-tabs--radio-style .nav-link.active:before {
    border: 1px solid var(--color-primary);
    background: var(--color-primary);
}
.nav-tabs--radio-style .nav-item.show .nav-link:after, .nav-tabs--radio-style .nav-link.active:after {
    content: "";
    display: block;
    position: absolute;
    left: 6px;
    top: 11px;
    width: 6px;
    height: 9px;
    border: 2px solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);

    /*@media screen and (min-width: 768px) {*/
    /*    top:9px;*/
    /*}*/
}