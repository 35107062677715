@media screen and (min-width: 768px) {
    .form-box {
        position: relative;
        margin:1rem auto;
        padding:2.5rem;
        width: 100%;
        max-width:480px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 0 40px 0 rgba(0,0,0,0.25);
    }
    .form-box--wide {
        max-width:1380px;
    }
}
.or-divider {
    position: relative;
}
.or-divider:before {
    content:'';
    position: absolute;
    width:auto;
    height:1px;
    background:#ccc;
    top:50%;
    left:0;
    right:0;
    z-index: 1;
}
.or-divider span {
    position: relative;
    display: inline-block;
    padding:8px;
    background: #fff;
    z-index: 5;
}

.form a:not(.btn),
.form-box a:not(.btn) {
    color:var(--color-primary);
}


.form-group {
    position: relative;
    margin-bottom:1.125rem;
    @media screen and (min-width: 768px) {
        margin-bottom:1.5rem;
    }
}
.form-group--additionally {
    margin-top:-1rem;
    @media screen and (min-width: 768px) {
        margin-top:-1rem;
    }
}
.form-group-inline {
    display: inline-block;
}

.form-group--append .form-group-append {
    position: absolute;
    right:1rem;
    top:.625rem;
}
.form-group--append .form-control {
    padding-right:2.5rem;
}
.form-control--narrow {
    width:100%;
    max-width:220px;
}
.form-control--extra-narrow {
    width:100%;
    max-width:90px;
}
.form-control {
    height:2.75rem;
    padding:.5rem 1.125rem;
    outline:0;
    border-radius:100px;
    font-size: 16px;
    font-weight: 300;
    @media screen and (min-width: 768px) {
        height:3.125rem;
        padding:.625rem 1.25rem .625rem 1.25rem;
    }
}
.form-control.form-control--hide-placeholder::-webkit-input-placeholder { /* WebKit browsers */
    color:transparent;
}
.form-control.form-control--hide-placeholder:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:transparent;
}
.form-control.form-control--hide-placeholder::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:transparent;
}
.form-control.form-control--hide-placeholder:-ms-input-placeholder { /* Internet Explorer 10+ */
    color:transparent;
}



.form-control.form-control--blank {
    border:0;
    padding:0 1rem;
}
.form-control-nostyle {
    border:0;
    margin:0;
    padding:0;
    /*-moz-appearance: none;
    -webkit-appearance: none;*/
}
.form-control-nostyle::-ms-expand {
    /*display: none;*/
}
.form-group--with-datepicker-has-dash:before {
    content:'';
    display: block;
    position: absolute;
    left:100%;
    top:1.5rem;
    height:1px;
    width:50px;
    background-color: #ced4da;
}

@media screen and (max-width: 767px) {
    .form-group--with-datepicker-has-dash-hide-mobile:before {
        display: none;
    }
}
.form-group.form-group--with-datepicker .form-control {
    padding-left:3.25rem;
}
.form-group.form-group--with-datepicker:after {
    content:var(--icon-calendar);
    font-family: iconfont;
    font-size:1.125rem;
    position: absolute;
    left:1.375rem;
    top:.5rem;
}
.has-error .form-control {
    border-color:var(--color-danger);
}
select.form-control {
    cursor: pointer;
    -webkit-appearance: none;
    @media screen and (min-width: 768px) {
        padding-left:1rem;
        padding-top:.75rem;
    }
}
textarea.form-control {
    padding-top:1.25rem;
    border-radius:10px;
}
.form-control:disabled,
.form-control[readonly] {
    background-color: #fff;
    opacity: 1;
}


/*custom-file-upload*/
.custom-file-upload input[type=file] {
    font-size: 250px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
}
.custom-file-upload .custom-file-upload__inner {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border:1px dashed #c7c7c7;
}
.custom-file-upload:hover .custom-file-upload__inner {
    border:1px dashed #000;
}
.custom-file-upload__body {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.btn.custom-file-upload__desc {
    text-transform: none;
    color: #8F8F8F;
    letter-spacing: 0;
}