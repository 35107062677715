.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 1.75rem;
}
/*.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}*/
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
    /*text-decoration: underline;*/
    text-decoration: none;
    font-weight: var(--font-default-bold-weight);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-text-default);
    text-decoration: underline;
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5 {
    margin-top:1.5rem;
    margin-bottom:.875rem;
    @media screen and (min-width: 768px) {
        margin-top:2.5rem;
        margin-bottom:1.5rem;
    }
}

.wysiwyg h2 {
    text-transform: none;
    letter-spacing: normal;
}


.wysiwyg h2+h1 {
    margin-top:-.25rem;
    @media screen and (min-width: 768px) {
        margin-top:-.375rem;
    }
}


.wysiwyg h2 {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    font-size: calc(21rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(30rem/16);
    }
}
@supports (font-variation-settings: "wdth" 315) {
    .wysiwyg h2 {
        font-family: var(--font-default-vf);
    }
}

.wysiwyg ul>li {
    margin-bottom:.375rem;
    margin-left:.5rem;
    padding-left:.5rem;
    list-style: none;
    position: relative;
    @media screen and (max-width: 767px) {
        margin-left:0;
    }
}
.wysiwyg ul>li:before {
    content:'';
    position: absolute;
    left:-1.25rem;
    top:10px;
    display: block;
    width:12px;
    height:12px;
    background: var(--color-primary);
    border-radius: 50%;
    /*@media screen and (max-width: 767px) {*/
    /*    top:.25rem;*/
    /*}*/
}

.wysiwyg ol>li {
    margin-bottom:.375rem;
    margin-left:.5rem;
    padding-left:.5rem;
    @media screen and (max-width: 767px) {
        margin-left:0;
    }
}


@media screen and (min-width: 768px) {
    .multicol-list {
        width: 100%;
        column-count: 2;
        column-gap: 30px;
        padding-left:1rem;
    }
    .multicol-list.multicol-list--3 {
        column-count: 3;
    }
}


@media screen and (min-width: 768px) {
    .text-container-heading {
        margin-left:4rem;
    }
    .text-container {
        position: relative;
        margin-left:-7.75rem;
        padding:4rem;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 15px 40px 0 rgba(0,0,0,0.15);
        z-index:50;
    }
    .text-container--right {
        margin-left:0;
        margin-right:-7.5rem;
    }
}