.slider:not(.slick-slider)>div+div{
    display: none;
}

.slider {
    position: relative;
}

.slick-slider {
    line-height: 0;
}
.slick-slider .wysiwyg {
    line-height: 1.75;
}

.slick-slider .slick-dots{
    display: none !important;
}

/* arrows */
.slick-arrow {
    position: absolute;
    right:0;
    top:50%;
    margin-top:-2rem;
    color: var(--color-primary);
    font-size:4rem;
    width:4rem;
    height:4rem;
    background: transparent;
    border-radius:0;
    line-height: 0;
    cursor: pointer;
    z-index: 90;
    border:0;
    outline:0 !important;
    opacity:.75;

    @media screen and (max-width: 767px) {
        margin-top:-1.5rem;
        font-size:3rem;
        width:3rem;
        height:3rem;
    }
}

.slick-arrow.slick-disabled {
    /*display: none !important;*/
    cursor: not-allowed;
    opacity: .25;
}

.slick-prev {
    right:auto;
    left:0;
    transform:scale(-1);
    @media screen and (max-width: 767px) {
        left:0;
    }
}

.slick-arrow:not(.slick-disabled):hover,
.slick-arrow:not(.slick-disabled):focus {
    opacity: 1;
}