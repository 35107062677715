.table table,
.wysiwyg table{
    width: 100% !important; /* override width from editor */
    max-width: 100% !important; /* override width from editor */
    border:0;
    margin:0 auto;
}
.table table > thead > tr > th,
.table table > tbody > tr > th,
.table table > tfoot > tr > th,
.table table > thead > tr > td,
.table table > tbody > tr > td,
.table table > tfoot > tr > td,
.wysiwyg table > thead > tr > th,
.wysiwyg table > tbody > tr > th,
.wysiwyg table > tfoot > tr > th,
.wysiwyg table > thead > tr > td,
.wysiwyg table > tbody > tr > td,
.wysiwyg table > tfoot > tr > td{
    padding: .5rem;
    line-height: 1.5;
    vertical-align: middle;
    text-align:left;
    background-color: #fff;
    border:0;
    @media screen and (min-width: 768px){
        padding: 1.375rem  1.5rem;
    }
}


.table table > thead > tr:last-child > th,
.table table > tbody > tr:last-child > th,
.table table > tfoot > tr:last-child > th,
.table table > thead > tr:last-child > td,
.table table > tbody > tr:last-child > td,
.table table > tfoot > tr:last-child > td,
.wysiwyg table > thead > tr:last-child > th,
.wysiwyg table > tbody > tr:last-child > th,
.wysiwyg table > tfoot > tr:last-child > th,
.wysiwyg table > thead > tr:last-child > td,
.wysiwyg table > tbody > tr:last-child > td,
.wysiwyg table > tfoot > tr:last-child > td{
    /*border-bottom: 1px solid var(--color-light-grey);*/
}
.wysiwyg table > thead > tr > th,
.table table > thead > tr > th {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    @media screen and (min-width: 768px){
        font-size: 1.375rem;
        padding: 1.25rem  1.5rem;
    }
}
@supports (font-variation-settings: "wdth" 315) {
    .wysiwyg table > thead > tr > th,
    .table table > thead > tr > th {
        font-family: var(--font-default-vf);
    }
}
.wysiwyg table > tbody > tr:nth-of-type(odd)>td,
.wysiwyg table > tbody > tr:nth-of-type(odd)>th,
.table table > tbody > tr:nth-of-type(odd)>td,
.table table > tbody > tr:nth-of-type(odd)>th {
    background: var(--color-light-grey);
}
.table-hover > tbody > tr:hover {
    background-color: var(--color-light-grey);
}

table col[class*="col-"] {
    position: static;
    display: table-column;
    float: none;
}
table td[class*="col-"],
table th[class*="col-"] {
    position: static;
    display: table-cell;
    float: none;
}
.table-responsive {
    min-height: .01%;
    overflow-x: auto;
}
@media screen and (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 1rem;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border:1px solid var(--color-light-grey);
    }
    .table-responsive table {
        margin-bottom: 0;
        border:0;
    }
    .table-responsive table th,
    .table-responsive table td {
        white-space: nowrap;
    }
}