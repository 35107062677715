.account-layout {
    padding:2rem 0 4rem 0;
}

.publish-checkbox {
    position: absolute;
    left:-19999px;
}

/* cols */
@media screen and (min-width: 768px) {
    .account-layout__left {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .left-col {
        padding:1.375rem;
        border-radius: 10px;
        box-shadow: 0 15px 40px 0 rgba(0,0,0,0.15);
        background-color: #fff;
    }
    .account-layout__content {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media screen and (min-width: 1300px) {
    .account-layout__left {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .account-layout__content {
        flex: 0 0 100%;
        max-width:100%;
    }
}



/* box */
.account-box {
    position: relative;
    margin:0 0 1rem 0;
}
.account-box__headline{
    margin:0 0 1rem 0;
}
@media screen and (min-width: 768px) {
    .account-box {
        margin:1rem auto 3rem auto;
        padding:2rem;
        border-radius: 10px;
        border: 1px solid #C7C7C7;
    }
    .account-box__bottom {
        position: absolute;
        bottom:-1.75rem;
        right:0;
        left:0;
    }
    .account-box__headline{
        margin:-2.75rem 0 2rem -.5rem;
    }
    .account-box__top-container{
        margin:-3.25rem 0 1rem -.5rem;
    }
    .account-box__top-container .account-box__headline{
        margin:0;
    }
    .account-box__headline>a,
    .account-box__headline>span{
        display: inline-block;
        background: #fff;
        padding:0 .5rem;
        position: relative;
    }
}

.account-box__headline>a[data-toggle="collapse"]{
    display: block;
    color:inherit;
    border-bottom:1px solid;
}
.account-box__headline>a[data-toggle="collapse"]:after{
    display: inline-block;
    position: relative;
    content:'+';
    font-size:1.5rem;
    margin-left:.5rem;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    color:inherit;
    transform: rotate(45deg);
}
@supports (font-variation-settings: "wdth" 315) {
    .account-box__headline>a[data-toggle="collapse"]:after{
        font-family: var(--font-default-vf);
    }
}

.account-box__headline>a.collapsed:after{
    transform: rotate(0);
}



/* lang swith */
.language-tabs {
    position: relative;
    margin:.5rem 0 0 0;

@media screen and (min-width: 768px) {
    margin:0 0 0 1rem;
    top:-.375rem;
}
}
.language-tabs> a {
    margin-right:.75rem;
}



/* address */
.account-addressbox {
    position: relative;
    margin:0;
    padding:1rem 0;
    border-bottom: 1px solid #C7C7C7;

    @media screen and (min-width: 768px) {
        margin:0 -2rem 0 -2rem;
        padding:1rem 2rem;
    }
}
.account-addressbox__toggler {
    display: block;
    padding-right:1.5rem;
    position:relative;
    @media screen and (max-width: 767px) {
        font-size:.875rem;
    }
}
.account-addressbox__toggler:after {
    content:'';
    position: absolute;
    right:0;
    top:50%;
    transform: translateY(-50%) rotate(45deg) scale(-1);
    width:.5rem;
    height:.5rem;
    border:1px solid #ccc;
    border-width: 0 1px 1px 0;
}
.account-addressbox__toggler.collapsed:after {
    transform: translateY(-50%) rotate(45deg);
}
.account-addressbox__body {
    padding:2rem 0;
}



/* objekte */
.account-objekt {
    position: relative;
    border-radius: 10px;
    border: 1px solid #C7C7C7;
    margin:1.75rem 0;
}
.account-objekt__img {
    overflow: hidden;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    background: var(--color-light-grey);
}
.account-objekt__img--unpublished .account-objekt__img-img {
    opacity: .5;
}
.account-objekt__unpublished {
    position: absolute;
    left:-4rem;
    top:3rem;
    text-align: center;
    padding: .75rem 7rem;
    width:25rem;
    color:#fff;
    font-size: 1.125rem;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    background: linear-gradient(127.06deg, #B845C2 0%, #82218F 100%);
    transform: rotate(-30deg);
    white-space: nowrap;

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        left:-6rem;
    }
}
@supports (font-variation-settings: "wdth" 315) {
    .account-objekt__unpublished {
        font-family: var(--font-default-vf);
    }
}


.account-objekt__img .icon {
    color:#fff;
    font-size:3rem;
    position: absolute;
    left:50%;
    top:50%;
    transform: translateX(-50%) translateY(-50%);
}
.account-objekt__body {
    padding:1rem 1.5rem 1rem 1rem;

    @media screen and (max-width: 767px) {
        padding-top:0;
    }
}
.account-objekt__msg-hint {
    position: absolute;
    right:-10px;
    top:-10px;
    font-size:1.75rem;
    z-index:10;
}


/* nav */
.account-nav {
    margin:0;
}
ul.account-nav ul {
    display: none;
}
ul.account-nav > li.active > ul {
    display: block;
}
.account-nav a {
    padding:.25rem .75rem .25rem 2rem;
    position: relative;
    display:block;
    font-size:1.125rem;
}
.account-nav .nav-link__msg-hint {
    position: absolute;
    right:0;
    top:50%;
    margin-top:-.75rem;
    border-radius: 50%;
    display: inline-block;
    width:1rem;
    height:1rem;
    color:#fff;
    font-size: .75rem;
    line-height:1rem;
    text-align: center;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    background: linear-gradient(127.06deg, #B845C2 0%, #82218F 100%);
}
@supports (font-variation-settings: "wdth" 315) {
    .account-nav .nav-link__msg-hint {
        font-family: var(--font-default-vf);
    }
}
.account-nav li.has-children> a:after {
    content:'';
    position: absolute;
    right:0;
    top:.875rem;
    width:.5rem;
    height:.5rem;
    border:1px solid #ccc;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
}
.account-nav li.has-children.active> a:after {
    transform: rotate(45deg) scale(-1);
}
.account-nav a:hover,
.account-nav a:focus {
    color: var(--color-primary);
}
.account-nav a>.icon {
    position: absolute;
    left:0;
    top: .5rem;
}
.account-nav li.active > a {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}
@supports (font-variation-settings: "wdth" 315) {
    .account-nav li.active > a {
        font-family: var(--font-default-vf);
    }
}
@media screen and (max-width: 767px) {

    .dropdown-menu.account-nav {
        margin:0;
        border:0;
        padding:1.375rem;
        border-radius: 10px;
        box-shadow: 0 15px 40px 0 rgba(0,0,0,0.15);
        background-color: #fff;
    }
}


/* steps nav */
.account-layout__steps-nav {
    border-radius: 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
    box-shadow: 0 5px 18px 0 rgba(0,0,0,0.2);
}
.account-layout__steps-nav .nav-link {
    position: relative;
    padding:1rem 0;
    @media screen and (max-width: 767px) {
        padding:1rem;
    }
}
.account-layout__steps-nav .nav-link:hover,
.account-layout__steps-nav .nav-link:focus {
    /*color: var(--color-primary);*/
}
.account-layout__steps-nav .nav-link.active {
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}
@supports (font-variation-settings: "wdth" 315) {
    .account-layout__steps-nav .nav-link.active {
        font-family: var(--font-default-vf);
    }
}
.account-layout__steps-nav .nav-link.disabled {
    opacity: .5;
    pointer-events: none;
}
.account-layout__steps-nav .nav-link.has-success {
    color:var(--color-primary);
}
.account-layout__steps-nav .nav-link.has-error {
    color:var(--color-danger);
}
.account-layout__steps-nav .nav-link.has-success:before,
.account-layout__steps-nav .nav-link.has-error:before {
    content: var(--icon-help);
    font-family: iconfont;
    position: relative;
    margin-right:.5rem;
    top:.125rem;
    font-size:1.125rem;
    color:var(--color-danger);
}
.account-layout__steps-nav .nav-link.has-success:before {
    content: var(--icon-help);
    color:var(--color-primary);
}


@media screen and (max-width: 767px) {
    .account-layout__steps-nav {
        display: block;
        position: relative;
        width: 100%;
        letter-spacing: 1px;
    }
    /*.account-layout__steps-nav:after {
        position: absolute;
        display: block;
        content: "";
        right: 0;
        top: 0;
        bottom: 0;
        width: 60px;
        height: auto;
        z-index: 4;
        background: linear-gradient(90deg,hsla(0,0%,100%,0) 0,#fff);
    }*/
    .account-layout__steps-nav nav {
        font-size: .875rem;
        display: block;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
    }
    .account-layout__steps-nav nav .nav-link {
        display: inline-block;
    }
    .account-layout__steps-nav nav .nav-link:last-of-type {
        padding-right: 2rem;
    }
}