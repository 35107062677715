@media screen and (min-width: 768px) {
    .landlord-package {
        position: relative;
        margin:1rem auto;
        padding:2.5rem 2rem 1.125rem 2rem;
        width: 100%;
        max-width:480px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 0 40px 0 rgba(0,0,0,0.25);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
    }
    .landlord-package__top {
        justify-content: normal;
        margin-bottom:-3rem;
    }
    .landlord-package__bottom {
        align-self: flex-end;
        width: 100%;
    }
}


@media screen and (max-width: 767px) {
    .landlord-package {
        padding:2rem 1.5rem .125rem 1.5rem;
        margin-bottom:2rem;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 0 40px 0 rgba(0,0,0,0.25);
    }
}