.contact-partner {
    border: 1px solid #c7c7c7;
    border-radius: 10px;
    display: inline-block;
    margin-top: 20px;
}
.contact-partner .part {
    border-left: 1px solid black;
}
.contact-partner a {
    display: block;
    float: left;
    color: #82218f;
    padding: 15px 10px 10px 10px;
    width: 125px;
    height: 80px;
    text-align: center;
    text-transform: uppercase;
    font-weight: var(--font-default-semibold-weight);
}

.contact-partner a.disabled {
    color: #c7c7c7;
}

.contact-partner a:not(.no-border) {
    border-left: 1px solid #c7c7c7;
}

.contact-partner a i{
    display: block;
    font-size: 35px;
}

.partner-infos .has-icon-left {
    /*padding-left: 15px;*/
    margin-left: 15px;
}

.partner-infos .partner-block-part .icon:not(.icon-chevron) {
    position: absolute;
    top: 5px;
    left: 0;
}
.address-infos .has-icon-left {
    /*padding-left: 15px;*/
    margin-left: 10px;
}
.address-infos .icon {
    position: absolute;
    top: 5px;
    left: 0;
}

.account-box {
    margin:1rem auto 3rem auto;
    padding:2rem;
    border-radius: 10px;
    border: 1px solid #C7C7C7;
}
.account-box__bottom {
    position: absolute;
    bottom:-1.75rem;
    right:0;
    left:0;
}

/*dashboardstuff*/

.admin-dashboard .account-box__headline{
    margin:-2.75rem 0 2rem -.5rem;
}
.admin-dashboard .account-box__top-container{
    margin:-3.25rem 0 1rem -.5rem;
}
.admin-dashboard .account-box__top-container .account-box__headline{
    margin:0;
}
.admin-dashboard .account-box__headline>a,
.admin-dashboard .account-box__headline>span{
    display: inline-block;
    background: #fff;
    padding:0 .5rem;
    position: relative;
}
.admin-dashboard .btn {
    padding: 0.875rem 1.625rem;
    font-size: .9375rem;
    letter-spacing: 1.25px;
}

.message-buttons {
    padding: 1.5rem;
    text-align: center;
}
.message-buttons .other-btns .not-a-btn:first-child {
    padding-left: 0;
}

@media(max-width: 768px) {
    .message-buttons .btn {
        width: 100%;
    }
    .reservation-head .btn {
        width: 100%;
    }
    .account-box__bottom {
        bottom: -1.25rem;
    }

    .admin-dashboard .btn {
        padding: 0.625rem 1.5rem;
        /*font-size: .75rem;*/
        letter-spacing: 1px;
    }
    .contact-partner {
        width: 100%;
    }
    .contact-partner a {
        width: 33%;
    }
}