.main-img-container {
    position: relative;
}
.main-img {
    position: relative;
    width: 100%;
    height:280px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.main-img--portal {
    height:100svh;
}
.main-img__overlay {
    color:#fff;
    position: absolute;
    left:0;
    right:0;
    bottom:1.75rem;
}
.main-img__overlay--dark {
    color:var(--color-text-default);
}
.main-img__overlay--center {
    bottom:auto;
    top:50%;
    transform: translateY(-50%);
}


@media screen and (min-width: 768px) {
    .main-img {
        height:490px;
    }
    .main-img--portal {
        height:100svh;
    }
    .main-img-container--with-scroll-hint:after,
    .main-img-container--with-scroll-hint:before {
        content:'';
        display: inline-block;
        position: absolute;
        left:50%;
        transform: translateX(-50%);
        z-index:10;
    }
    .main-img-container--with-scroll-hint:before {
        bottom:-60px;
        width: 2px;
        height:120px;
        background-color: var(--color-primary);
    }
    .main-img-container--with-scroll-hint:after {
        bottom:70px;
        font-family: iconfont;
        content:var(--icon-mouse);
        font-size:1.75rem;
        color:var(--color-primary);
    }
}