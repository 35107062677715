.quickfinder {
    width: 100%;
    max-width:480px;
    position: relative;
    margin: 0 auto;
    padding:1.5rem;
    border-radius: 10px;
    box-shadow: 0 15px 40px 0 rgba(0,0,0,0.15);
    background-color: #fff;
    @media screen and (min-width: 768px) {
        padding:2.5rem;
    }
}