.custom-radio ul.parsley-errors-list.filled,
.custom-checkbox ul.parsley-errors-list.filled,
.form-errors {
    position: absolute;
    left:0;
    bottom:-1.5rem;
    color:#fff;
    background: var(--color-danger);
    font-size:13px;
    padding:.125rem .375rem;
    border-radius: .25rem;
    z-index:10;
    pointer-events: none;
    white-space: nowrap;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.4);

    @media screen and (min-width: 768px) {
        font-size:14px;
    }
}
.custom-radio ul.parsley-errors-list.filled:before,
.custom-checkbox ul.parsley-errors-list.filled:before,
.form-errors ul.parsley-errors-list.filled:before {
    content:'';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent var(--color-danger) transparent;
    position: absolute;
    left:1.25rem;
    top:-5px;
}
.custom-radio ul.parsley-errors-list.filled,
.custom-checkbox ul.parsley-errors-list.filled{
    left:1.75rem;
    bottom:-.75rem;
}
.has-success .form-errors,
.form-errors:empty {
    display: none;
}
.custom-radio ul,
.custom-checkbox ul,
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}