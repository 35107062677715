.teaser-slider .slick-list {
    padding:1.5rem 0 4rem 1.5rem;
    @media screen and (min-width: 768px) {
        padding:3rem 3rem 3rem 0;
    }
}
.teaser-slider .teaser {
    margin:0 .75rem;
    @media screen and (min-width: 768px) {
        margin:0 1rem;
    }
}
.teaser-slider .teaser__top {
    top:-4px;
}




/* arrows */

.teaser-arrow-holder {
    position: absolute;
    right: 1.375rem;
    top: -1rem;
    width: 100px;
}


@media screen and (min-width: 768px) {
    .teaser-arrow-holder {
        right: 2rem;
        top: 0;
        width: 80px;
        bottom: 0;
    }
    .teaser-arrow-holder .slick-arrow.slick-arrow {
        left:auto;
        right: 3rem;
    }
    .teaser-arrow-holder .slick-arrow.slick-prev {
        margin-top:1.75rem;
        right:2.375rem;
    }
    .teaser-arrow-holder .slick-arrow.slick-next {
        margin-top:-3.75rem;
    }
}