.has-reservation {
    /*#ffde30*/
    /*background-color: #c8c135 !important;*/
    /*background: linear-gradient(127.06deg,#c8c135,#ffde30);*/
    background: linear-gradient(127.06deg,var(--color-res-state-awaiting-renter),var(--color-res-state-awaiting-renter-light));
    border: 1px solid #c8c135;
}
.has-reservation.hover {
    background: linear-gradient(127.06deg,var(--color-res-state-awaiting-renter-light),var(--color-res-state-awaiting-renter));
}

.has-reservation.awaiting-landlord {
    /*background-color: #c8c135 !important;*/
    /*background: linear-gradient(127.06deg,#b845c2,#82218f);*/
    /*background: linear-gradient(127.06deg,#a87df1,#b485ff);*/
    background: linear-gradient(127.06deg,var(--color-res-state-awaiting-landlord),var(--color-res-state-awaiting-landlord-light));
    border: 1px solid #a87df1;
}
.has-reservation.awaiting-landlord.hover {
    background: linear-gradient(127.06deg,var(--color-res-state-awaiting-landlord-light),var(--color-res-state-awaiting-landlord));
}

.has-reservation.completed {
    /*background-color: #6fa543 !important;*/
    background: linear-gradient(127.06deg,var(--color-res-state-completed),var(--color-res-state-completed-light));
    border: 1px solid #6fa543;
}
.has-reservation.completed.hover {
    /*background-color: #6fa543 !important;*/
    background: linear-gradient(127.06deg,var(--color-res-state-completed-light),var(--color-res-state-completed));
}
.has-reservation.canceled:not(timedout) {
    /*background-color: #a63b4b !important;*/
    background: linear-gradient(127.06deg,var(--color-res-state-canceled),var(--color-res-state-canceled-light));
    border: 1px solid #a63b4b;
}
.has-reservation.canceled.hover:not(timedout) {
    /*background-color: #a63b4b !important;*/
    background: linear-gradient(127.06deg,var(--color-res-state-canceled-light),var(--color-res-state-canceled));
}

.has-reservation.canceled.timedout {
    /*background-color: #d86137 !important;*/
    background: linear-gradient(127.06deg,var(--color-res-state-timedout),var(--color-res-state-timedout-light));
    border: 1px solid #d86137;
}
.has-reservation.canceled.timedout.hover {
    /*background-color: #d86137 !important;*/
    background: linear-gradient(127.06deg,var(--color-res-state-timedout-light),var(--color-res-state-timedout));
}

.fc {
    font-size: 16px;
}

.fc .fc-highlight {
    border: 2px solid black;
    background: none;
}

.fc .fc-daygrid-day-frame.hover {
    border: 1px solid black;
    margin: -1px;
}

#detail-tools {
    margin: 20px;
    text-align: center;
}

#detail-tools button {
    width: 100%;
}


/*.fc-more-link {*/
/*    display: none;*/
/*}*/
.fc .fc-multimonth-title {
    font-weight: var(--font-default-semibold-weight);
}
.fc .fc-day {
    height: 80px;
    /*width: 80px;*/
    position: relative;
}
.fc .fc-day.fc-col-header-cell {
    height: 20px;
}

.fc .fc-daygrid-event-harness-abs {
    top: 0 !important;
}

.fc .fc-daygrid-event-harness {
    cursor: pointer;
}
.fc .fc-daygrid-event-harness:hover {

}

.fc .fc-more-link {
    display: none !important;
}


.fc-event {
    padding: 10px;
    height: 40px;
    line-height: 15px;
    font-size: 20px;
}

.fc-event.fc-event-start .fc-event-main {
    margin-left: 35px;
}

.fc-event .icon.icon-kzm-clockv2 {
    padding: 10px;
    height: 38px;
    line-height: 15px;
    font-size: 20px;
    position: absolute;
    top: 0;
    left: -3px;
    /*background: #6FA543;*/
    color: #fff;
    border-radius: 25px;
    /*border: 1px solid #6FA543;*/
    background: #ccb227;
    border: 1px solid #ccb227;
    z-index: 1000;
}

.fc-event.completed .icon.icon-kzm-clockv2 {
    background: darkgreen;
    border: 1px solid darkgreen;
}
.fc-event.awaiting-landlord .icon.icon-kzm-clockv2 {
    background: #7b5bb3;
    border: 1px solid #7b5bb3;
}
.fc-event.canceled .icon.icon-kzm-clockv2 {
    background: #6b2731;
    border: 1px solid #6b2731;
}
.fc-event.canceled.timedout .icon.icon-kzm-clockv2 {
    background: #964326;
    border: 1px solid #964326;
}

.fc .fc-day .blocked-icon.icon-top-left {
    top: 0.4rem;
    left: 0.4rem;
    right: unset;
    bottom: unset;
}

.fc-event.fc-event-start {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.fc-event.fc-event-end {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.fc .fc-multimonth {
    border: none;
}

.fc .fc-day.blocked {
    background: repeating-linear-gradient(  135deg,  #fff,  #fff 4px,  #c7c7c7 5px,  #c7c7c7 6px);
}

.fc .fc-day.blocked-end {
    background: repeating-linear-gradient(  135deg,  transparent,  transparent 49%,  #fff 50%,  #fff 100%), repeating-linear-gradient(  135deg,  #fff,  #fff 4px,  #c7c7c7 5px,  #c7c7c7 6px);
}
.fc .fc-day.blocked-start {
    background: repeating-linear-gradient(  135deg,  #fff, #fff 49%,  transparent 50%,  transparent 100%), repeating-linear-gradient(  135deg,  #fff,  #fff 4px,  #c7c7c7 5px,  #c7c7c7 6px);
}

.fc .fc-day.fc-day-today.blocked {
    background: repeating-linear-gradient(  135deg,  #fffadf,  #fffadf 4px,  #c7c7c7 5px,  #c7c7c7 6px);
}
.fc .fc-day.fc-day-today.blocked-start {
    background: repeating-linear-gradient(  135deg,  #fffadf, #fffadf 49%,  transparent 50%,  transparent 100%), repeating-linear-gradient(  135deg,  #fffadf,  #fffadf 4px,  #c7c7c7 5px,  #c7c7c7 6px);
}
.fc .fc-day.fc-day-today.blocked-end {
    background: repeating-linear-gradient(  135deg,  transparent,  transparent 49%,  #fffadf 50%,  #fffadf 100%), repeating-linear-gradient(  135deg,  #fffadf,  #fffadf 4px,  #c7c7c7 5px,  #c7c7c7 6px);
}

.fc .fc-day.fc-day-past.blocked {
    background: repeating-linear-gradient(  135deg,  #f1f3f4,  #f1f3f4 4px,  #c7c7c7 5px,  #c7c7c7 6px);
}
.fc .fc-day.fc-day-past.blocked-start {
    background: repeating-linear-gradient(  135deg,  #f1f3f4, #f1f3f4 49%,  transparent 50%,  transparent 100%), repeating-linear-gradient(  135deg,  #f1f3f4,  #f1f3f4 4px,  #c7c7c7 5px,  #c7c7c7 6px);
}
.fc .fc-day.fc-day-past.blocked-end {
    background: repeating-linear-gradient(  135deg,  transparent,  transparent 49%,  #f1f3f4 50%,  #f1f3f4 100%), repeating-linear-gradient(  135deg,  #f1f3f4,  #f1f3f4 4px,  #c7c7c7 5px,  #c7c7c7 6px);
}

/*.fc .blocked, .fc .blocked-end, .fc .blocked-star {*/
/*    z-index: -2;*/
/*}*/

.fc .fc-day .blocked-icon {
    bottom: 0.4rem;
    right: 0.4rem;
    position: absolute;
    /*color: #78bb32;*/
    color: #000;
    font-size: 25px;
    padding: 5px;
    background: #fff;
    border-radius: 18px;
    z-index: 100;
}
/*.fc .fc-daygrid-body-balanced .fc-daygrid-day-events {*/
/*    top: 40px;*/
/*}*/
.test123 {
    height: 10px;
}
.fc-day.fc-day-disabled {
    background: none !important;
    border: none !important;
}


.fc-toolbar-title {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
    color: #323232;
    font-size: 16px;
    line-height: 1.75;
    text-transform: uppercase;
}
@supports (font-variation-settings: "wdth" 315) {
    .fc-toolbar-title {
        font-family: var(--font-default-vf);
    }
}
.fc .fc-toolbar-chunk:nth-child(1), .fc .fc-toolbar-chunk:nth-child(3) {
    width: 50%;
}
.fc .fc-toolbar-chunk:nth-child(1) {
    text-align: center;
}
.fc .fc-toolbar-chunk:nth-child(2) {
    width: 0;
}
.fc .fc-toolbar-chunk:nth-child(3) {
    text-align: right;
}

.fc-toolbar {
    padding: 0 1.2em 1.2em;
}

.fc-toolbar .fc-button {
    display: inline-block;
    font-size: .75rem;
    text-transform: uppercase;
    border-radius: 999px;
    white-space: normal;
    box-shadow: 0 5px 18px 0 rgba(0,0,0,.2);
    border: 0;
    color: #323232;
    background-color: #fff;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding: 0.875rem 1rem;
    letter-spacing: 2px;
}
.fc-toolbar .fc-button:focus {
    box-shadow: 0 5px 18px 0 rgba(0,0,0,.2);
}
.fc-toolbar .fc-button-group {
    margin-bottom: 10px;
    margin-right: 10px;
}

.fc-customToday-button, .fc-customScrollToTop-button {
    margin-left: 0 !important;
    margin-bottom: 10px !important;
    margin-right: 10px !important;
}

.fc-toolbar .fc-button-group>.fc-button {

}

.fc-toolbar .fc-button-group>.fc-button:not(:last-child) {
    border-bottom-left-radius: 99px;
    border-top-left-radius: 99px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.fc-toolbar .fc-button-group>.fc-button:not(:first-child) {
    border-bottom-right-radius: 99px;
    border-top-right-radius: 99px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.fc .fc-button-primary:disabled {
    background-color: #fff;
    border-color: #323232;
    color: #323232;
}

#event-details-container {
    /*height: 1vh;*/
    padding-left:0;
    padding-right:0;
    border-left: var(--fc-border-color) 1px solid;
}

#event-details-container.static {
    position: static;
    /*margin-top: 157px;*/
    border-top: var(--fc-border-color) 1px solid;
}

#event-details-container.fixed {
    position: fixed;
    top: 125px;
    right: 0;
    margin-top: 0 !important;
    height: 100%;
}

body.fullcal-body #ical {
    text-align: left;
}

.detail-tools-headline {
    position: relative;
    word-wrap: break-word;
    font-size: 1.75em;
    margin: 0;
    color: #323232;
    line-height: 1.75;
    font-weight: var(--font-default-weight);
    /*text-transform: uppercase;*/
    display: block;
    padding-bottom: 25px;
}
.detail-tools-headline.has-icon-left {

}
.detail-tools-headline i {
    padding-right: 15px;
}

.calendar-tools form {
    padding: 0 1.2em 1.2em;
}
.close-iframe {
    width: auto !important;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1000;
    font-size: 25px !important;
    border-radius: 999px;
    border: 0;
    white-space: normal;
    box-shadow: 0 5px 18px 0 rgba(0,0,0,.2);
    color: #323232;
    background-color: #fff;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding: 10px !important;
    line-height: 1px;
}
.close-iframe:hover {
    color: #fff;
    background-color: #1f232a;
    border-color: #1f232a;
}
.iframe-menu-container {
    width: 100%;
    height: 65px;
    position: relative;
}

#event-details-frame-container {
    min-height: calc(100vh - 185px);
}
.fullcal-body .form-group span {
    line-height: 1.9em;
}

.no-cursor {
    cursor: default !important;
}

.fullcal-body {
    padding-top: 125px;
}
.fullcal-body header.dashboard {
    /*height: 125px;*/
}
header .fullcal-nav {
    position: relative;
    z-index: 10;
    /*background-color: #265c0e;*/
    /*background: linear-gradient(#075700,#074901);*/
    background: linear-gradient(#085801,#074901);
    /*margin-top: 20px;*/
    height: 35px;
}
header .fullcal-nav ul {
    padding: 0;
}
header .fullcal-nav .fc-nav-item {
    border-right: 1px solid #163608;
    /*background: linear-gradient(127.06deg,#265c0e,#306a13);*/
    background: linear-gradient(127.06deg,#074901,#085801);
    list-style: none;
    float: left;
    font-size: 15px;
    line-height: 35px;
    /*position: relative;*/
    /*text-transform: uppercase;*/
}
header .fullcal-nav .fc-nav-item a, header .fullcal-nav .open-reservation-content a {
    list-style: none;
    font-size: 15px;
    line-height: 34px;
    /*text-transform: uppercase;*/
    padding: 9px 0 9px 9px;
    position: relative;
}
header .fullcal-nav .fc-nav-item a:hover {
    background: linear-gradient(127.06deg,#085801,#074901);
    /*background: linear-gradient(127.06deg,#306a13,#265c0e);*/
}
/*header .fullcal-nav .fc-nav-item a span {*/
/*    padding: 0 45px;*/
/*}*/
.padding-left-med {
    padding-left: 45px;
}
.padding-right-med {
    padding-right: 45px;
}

header .fullcal-nav .fc-nav-item a .icon {
    font-size: 20px;
    position: absolute;
    top: 9px;
    left: 15px;
}
.fullcal-body .open-reservation-content {
    background-color: #265c0e;
    padding: 5px 15px;
    position: absolute;
    width: 100%;
    left: 0px;
    top: 35px;
    border-top: 1px solid #163608;
}
/*.fullcal-body .open-reservation-content .fullcal-nav-jumto {*/
/*    padding: 10px 30px;*/
/*}*/
.fullcal-body .open-reservation-content li {
    list-style: none;
    border-bottom: 1px solid #163608;
}
.fullcal-body .open-reservation-content li:last-child {
    border-bottom: none;
}
.fullcal-body .open-reservation-content li a {
    text-indent: -10px;
    padding-left: 10px;
    display: block;
}

/*.fc .fc-daygrid-bg-harness .fc-highlight {*/
/*    pointer-events: none;*/
/*}*/
.fc .fc-daygrid-bg-harness {
    pointer-events: none;
    z-index: 1;
}
#blocked-grund {
    height: 90px;
}

.fc .fc-multimonth-singlecol .fc-multimonth-daygrid-table, .fc .fc-multimonth-singlecol .fc-multimonth-header-table {
    border-left-style: inherit;
    border-right-style: inherit;
}

.btn {
    word-wrap: break-word;
    max-width: 100%;
}

.no-padding-left {
    padding-left: 0 !important;
}
.no-padding-right {
    padding-right: 0 !important;
}
#fullcalendar .hidden {
    display: none;
}
#detail-tools .custom-checkbox__box, #detail-tools .custom-radio__box {
    top: 6px;
}
.fc-day-past {
    background: #f1f3f4;
    /*var(--fc-border-color)*/
}

.fullcal-body .account-layout {
    padding: 4rem 0 4rem;
}

@media screen and (max-width: 767px) {
    body {
        user-select: none;
    }
    .detail-tools-headline {
        padding-bottom: 15px;
        font-size: 1em;
        font-weight: var(--font-default-semibold-weight);
    }
    .mobile-strong {
        font-weight: var(--font-default-semibold-weight);
    }
    #detail-tools {
        margin-top: 10px;
    }
    #detail-tools textarea.form-control {
        padding-top: 0.5rem;
        height: 2.5rem;
    }
    header .fullcal-nav .fc-nav-item {
        border-right: none;
    }

    .fullcal-body .account-layout {
        padding: 2rem 0 4rem;
    }
    .my-tasks-container {
        width: 100%;
        background: #265c0e !important;
    }
    .my-tasks-container:hover {
        background: #265c0e !important;
    }
    .my-tasks-container a {
        background: #265c0e !important;
    }
    .my-tasks-container a:hover {
        background: #265c0e !important;
    }
    #my-tasks-content .icon.icon-icon_confirm {
        top: 15px;
        left: 10px;
    }
    #my-tasks-content .icon.icon-icon_confirm {

    }
    .my-tasks-container .padding-left-med {
        padding-left: 35px;
    }
    .my-tasks-container #confirm-blocked .padding-left-med {
        padding-left: 30px;
    }



    #blocked-grund {
        height: 60px;
    }
    body.fullcal-body {
        padding-top: 85px;
    }
    header .fullcal-nav {
        margin-top: 0;
    }
    #event-details-frame-container {
        overflow: scroll;
        min-height: unset;
        height: 100%;
    }
    #event-details, #res-details {
        height: 100%;
    }
    #event-details-container {
        position: fixed !important;
        /*height: 170px;*/
        height: 125px;
        bottom: 0;
        left: 0;
        background: #fff;
        z-index: 1000;
        margin-top: 0 !important;
        border-top: var(--fc-border-color) 1px solid;
        touch-action: pinch-zoom;
    }
    #event-details-container a.show-container div:first-child {
        margin: auto;
        width: 50px;
    }
    #event-details-container a.show-container span {
        background-color: #e5e5e5;
        display: block;
        height: 5px;
        border-radius: 100px;
        margin: 5px auto;
    }
    #event-details-container a.show-container span:nth-child(1){
        width: 30px;
    }
    #event-details-container a.show-container span:nth-child(2){
        width: 50px
    }
    .fc .fc-day {
        height: 60px;
        width: auto;
        position: relative;
    }
    .fc-event.fc-event-start .fc-event-main {
        margin-left: 20px;
    }
    .fc-event {
        padding: 3px;
        height: 20px;
        line-height: 11px;
        font-size: 10px;
    }
    .fc-event .icon.icon-kzm-clockv2 {
        font-size: 10px;
        padding: 5px 3px;
        height: auto;
        line-height: 7px;
        left: -1px;
        top: -1px;
    }
    .fc .fc-day .blocked-icon {
        font-size: 15px;
        padding: 2px;
        bottom: 0.2rem;
        right: 0.2rem;
    }
    .fc .fc-day .blocked-icon.icon-top-lef {
        top: 0.2rem;
        left: 0.2rem;
        right: unset;
        bottom: unset;
    }
    .fc-toolbar-title {
        font-size: 20px !important;
    }
    .fc .fc-toolbar-chunk:nth-child(3) {
        text-align: center;
    }
    /*.fc-customToday-button, .fc-customScrollToTop-button {*/
    /*    margin-top: 15px !important;*/
    /*    margin-left: 0 !important;*/
    /*}*/
    .fullcal-nav .fc-nav-item span {
        /*display: none;*/
    }
    .padding-right-med {
        padding-right: 25px;
    }
    #fullcalendar {
        margin-bottom: 300px;
    }
    .fullcal-body header.dashboard {
        height: 70px;
    }
}

@media (max-width: 1200px) {
    #detail-tools button {
        padding: 15px;
        font-size: 14px;
    }
}

/*stuff for XXL*/
@media (min-width: 1400px) {
    .col-xxl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xxl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    #detail-tools button {
        padding: 15px;
        font-size: 14px;
    }
}

@media (min-width: 1900px) {
    #detail-tools button {
        padding: 0.875rem 2.5rem;
        font-size: .9375rem;
    }
}

@media (min-width: 576px) {
    .fullcal-body .open-reservation-content {
        padding: 0;
    }
    .fullcal-body .open-reservation-content .fullcal-nav-jumto {
        padding: 10px 30px;
    }
}


/* hover overrides and use hovers only for non touch */
.fc .fc-button-primary:hover {
    border: 0 !important;
    color: #323232 !important;
    background-color: #fff !important;
}


@media (hover: hover) and (pointer: fine) {
    .fc-toolbar .fc-button:hover {
        color: #fff !important;
        background-color: #1f232a !important;
        border-color: #1f232a !important;
    }
}