.lg-toolbar {
    background: none;
}

.lg-toolbar .lg-close:after{
    content: var(--icon-close);
    color: var(--color-white);
    font-size: calc(22rem/16);
    font-family: iconfont;
}

.lg-actions .lg-prev:after,
.lg-actions .lg-next:before {
    content: var(--icon-arrow-long);
    color: white;
    font-family: iconfont;
}

.lg-actions .lg-prev {
    transform: rotate(180deg);
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
    background: none;
}

.lg-actions .lg-prev:hover:after,
.lg-actions .lg-next:hover:before{
    color: var(--color-primary) ;
}

/* lightgallery iframe */
.lg-has-iframe {
    max-width: 930px !important;
}


/* lightbox items */
.lightbox__item {
    position: relative;
    display: block;
    transition: all 0s linear;
    color:transparent !important; /* bugfix for ie/edge */
}
@media screen and (min-width: 768px) {
    .lightbox__item:after{
        content: var(--icon-search);
        font-size:22px;
        font-family: iconfont;
        color:var(--color-text-default) !important; /* bugfix for ie/edge */
        position: absolute;
        left:50%;
        top:50%;
        width:50px;
        height:50px;
        line-height:50px;
        margin:-25px 0 0 -25px;
        text-align: center;
        z-index:40;
        opacity:0;
        background:#fff;
        border-radius: 50%;
    }
    .lightbox__item:before{
        content: " ";
        position: absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
        z-index:35;
        opacity:0;
        transition: all 0.3s linear;
        background:rgba(0,0,0,.3);
    }
    .lightbox__item:hover:before,
    .lightbox__item:focus:before{
        opacity:1;
    }
    .lightbox__item:hover:after,
    .lightbox__item:focus:after{
        opacity:1;
    }
}