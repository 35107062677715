.accordion {}
.accordion .card-body,
.accordion .card-header,
.accordion .card {
    border:0;
    padding:0;
    margin:0;
    background: transparent;
}
.accordion .card {
    margin:2rem 0;
}
.accordion .card-header{
    position: relative;
    display: block;
    top:1rem;
}
.accordion .card-header:before{
    content:'';
    position: absolute;
    left:1rem;
    right:1rem;
    bottom:.9375rem;
    width:auto;
    height:1px;
    background: #C7C7C7;
    pointer-events: none;
    z-index:4;
}
.accordion .card-header a{
    position: relative;
    cursor: pointer;
    display: block;
    padding-right: 4.5rem;
    z-index:6;
}
.accordion .card-header a>span{
    position: relative;
    display: inline-block;
    padding:0 .75rem;
    background: #fff;
    margin: 0 1.75rem 0 2.25rem;

    @media screen and (max-width: 767px) {
        margin-left:1.5rem;
        padding:0 .5rem;
    }
}
.accordion .card-header a .icon-chevron{
    display: inline-block;
    width:2rem;
    height:2rem;
    line-height: 1.75rem;
    background: #fff;
    position: absolute;
    right:2.75rem;
    bottom:-.125rem;
    color:var(--color-dark);
    font-size:1rem;
    text-align: center;
    transform: rotate(-90deg);
    @media screen and (max-width: 767px) {
        right:1.75rem;
    }
}
.accordion .card-header a.collapsed .icon-chevron{
    transform: rotate(90deg);
    right:3rem;
    @media screen and (max-width: 767px) {
        right:2rem;
    }
}

.accordion .card-body{
    border-radius: 10px !important;
    border: 1px solid #C7C7C7 !important;
    padding:2.5rem 4rem;

    @media screen and (max-width: 767px) {
        padding:1.5rem 1.75rem;
    }
}
.accordion .card-body p:last-child,
.accordion .card-body ul:last-child,
.accordion .card-body ol:last-child{
    margin: 0;
}