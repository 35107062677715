
.sf-toolbar.sf-display-none {
    display: none !important;
}
/*header .container-fluid {*/
/*    max-width: 100%;*/
/*}*/
.rdv2 #mainContent {

}
.rdv2 .main-portal {
    margin-bottom: -40px; /* counteract the usual footer margin on the rest of the site */
}
/*.rdv2 .main-portal .info-teaser-box {*/
/*    display: block;*/
/*    height: auto;*/
/*}*/

.rdv2 .main-portal .info-teasers .container.container--wide {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
}
.rdv2 .portal-search-desktop {
    z-index: 1000;
}
.rdv2 .header-light {
    background: rgba(30, 30, 30, 1);
    height: 70px;
    max-width: 100vw;
}

.rdv2 .navbar-brand-container {
    height: 100%;
    width: 100%;
    /*padding: 10px;*/
}
.rdv2 .navbar-brand {
    padding: 10px;
}

.rdv2 .header-light .navbar-brand-container {
    background: rgba(30, 30, 30, 1);
}

.rdv2 .content-block.content-block--with-background.black-info-box {
    padding-bottom: 0;
}
.rdv2 .main-portal h2 {
    font-size: 20px;
}
/*.rdv2 .black-info-box h2 {*/
/*    font-size: 20px;*/
/*}*/
.rdv2 .black-info-box .black-box {
    /*margin: 30px 0 65px 0;*/
    /*padding: 15px;*/
    padding-bottom: 100px;
    position: relative;
    height: 100%;
}
.rdv2 .black-info-box .black-box .icon-container {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 20px;
    left: 0;
}

.rdv2 .black-info-box .black-box .icon {
    position: relative;
    border-radius: 100%;
    height: 250px;
    width: 250px;
    font-size: 100px;
    line-height: 100px;
    color: #8E8E8E;
    opacity: 0.23;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
}
.rdv2 .black-info-box .black-box .icon::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rdv2 .black-info-box .portal-h3 {
    font-size: 30px;
    z-index: 2;
    position: relative;
    padding-top: 200px;
}
.rdv2 .black-info-box {
    color: #fff;
    font-weight: var(--font-default-medium-weight);
}
.rdv2 .black-info-box .blackbox-link {
    color: #E478EE;
    font-size: 24px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding-bottom: 20px;
}
.rdv2 .black-info-box .blackbox-link:hover {
    text-decoration: underline;
}

.rdv2 .main-portal h3 {
    font-size: 18px;
    font-weight: var(--font-default-weight);
}
.rdv2 .main-portal h4 {
    font-size: 40px;
    font-weight: var(--font-default-bold-weight);
    padding-bottom: 20px;
}
.rdv2 .main-portal .main-img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*padding-top: 70px;*/
}
.rdv2 .main-portal .main-img-container h1, .rdv2 .main-portal .main-img-container h3 {
    text-align: center;
    color: white;
}
.rdv2 .main-portal .main-img-container h1 {
    font-weight: var(--font-default-bold-weight);
    font-size: 40px;
    padding-left: 15px;
    padding-right: 15px;
}

.rdv2 .portal-main-h1 .h1-one {
    display: inline-block;
    white-space: nowrap;
}

.rdv2 .portal-main-h1 .h1-two {
    display: inline-block;
    white-space: normal;
}

.rdv2 .main-portal .main-img__overlay--search {
    position: absolute;
    top: 36%;
    transform: translateY(-50%);
    z-index: 5;
    bottom: unset;
    padding-top: 90px;
}

/*.rdv2 .main-img__overlay--partners {*/
/*    display: none;*/
/*}*/

.rdv2 .main-portal .main-img__overlay--partners {
    height: 220px;
    bottom: 0;
    background: transparent linear-gradient(180deg, #1E1E1E00 0%, #1E1E1E 100%) 0 0 no-repeat padding-box;
}
.rdv2 .main-portal .main-img__overlay--partners .container {
    padding-bottom: 20px;
    overflow-x: hidden;

}
/*.rdv2 .main-img__overlay--partners h3 {*/
/*    font-size: 18px;*/
/*}*/
.rdv2 .main-portal .main-img.main-img--portal {
    background-position: 50% 80% !important;
}
.rdv2 .main-portal .main-img.main-img--portal:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(7, 7, 7, 0.25) 0, rgba(7, 7, 7, 0.70));
}


/****** Search bar ******/

.rdv2 .portal-search {
    margin-top: 20px;
    border: 3px solid var(--color-secondary);
    position: relative;
    height: 100px;
    padding: 5px 0 5px 5px;
    outline: 0;
    border-radius: 100px;
    font-size: .9375rem;
}
.rdv2 .portal-search form {
    height: 100%;
}

.rdv2 .portal-search-container {
    width: calc(100% - 75px);
    position: relative;
    display: flex;
    height: 100%;
    border-radius: 100px 0 0 100px;
}
.rdv2 .portal-search-container form {
    display: flex;
}
.rdv2 .portal-search-click-arrow {
    position: absolute;
    width: 75px;
    background: #82218f;
    color: #fff;
    top: 0;
    right: 0;
    border-radius: 0 50px 50px 0;
    border: none;
    height: calc(100% - 10px);
    margin: 5px 5px 0 0;
    cursor: pointer;
}
.rdv2 .portal-search-click-arrow i {
    position: absolute;
    font-size: 40px;
    right: 25px;
    top: 23px;
}
.rdv2 .portal-search-container .portal-search-location {
    background: transparent linear-gradient(180deg,#f3f3f3,#fff) 0 0 no-repeat padding-box;
    padding: 8px 18px 8px 34px;
    width: 100%;
    margin-bottom: 0;
}
.rdv2 .portal-search-container .easypick-from-container {
    padding: 8px 5px 8px 30px;
}
.rdv2 .portal-search-container .easypick-to-container {
    padding: 8px 30px 8px 5px;
}
.rdv2 .portal-search-container .easypick-from, .rdv2 .portal-search-container .easypick-to {
    cursor: pointer;
}

.rdv2 .portal-search-container .portal-search-location,
.rdv2 .portal-search-container .portal-search-persons {
    position: relative;
    border-radius: 100px 0 0 100px;
}
.rdv2 .portal-search-container .portal-search-location {
    border-radius: 100px;
}


.rdv2 .portal-search-container .portal-search-location .portal-search__green{
    padding-left: 0;
}


.rdv2 .portal-search-container .portal-search-fromto {
    /*border-right: 2px dashed #E9E9E9;*/
    border-radius: 100px;
    position: relative;
}
.rdv2 .portal-search-container .portal-search-fromto .portal-search__black {
    position: relative;
}
.rdv2 .main-portal .icon-close,
.rdv2 .js-easypicker-container .icon-close {
    position: absolute;
    top: 10px;
    right: 3px;
    cursor: pointer;
    font-size: 11px;
    font-weight: 700;
    padding: 6px;
    box-sizing: border-box;
    border-radius: 50%;
    box-shadow: 0 5px 9px 0 rgba(0, 0, 0, .2);
}

/*.rdv2 .portal-search-mobile-pullup .portal-search-fromto.easypick-to-container .icon-close {*/
/*    right: 35px;*/
/*}*/

.rdv2 .js-easypicker-container .icon-close.hidden {
    display: none;
}
/*.js-easypick-wrapper {*/
/*    position: absolute;*/
/*    top: 75px;*/
/*    left: 30px;*/
/*}*/
.js-easypick-wrapper {
    position: absolute;
    top: 122px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
}
.easepick-wrapper {
    align-items: center;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.easepick-wrapper.padding-top-150 {
    padding-top: 150px;
}
.easepick-wrapper.padding-top-100 {
    padding-top: 100px;
}

.rdv2 .portal-search-container .js-easypicker-container {
    width: 55%;
    position: relative;
    display: flex;
}
.rdv2 .portal-search-container .js-easypicker-container .portal-search-fromto {
    width: 50%;
}

.rdv2 .portal-search-container .mobile-search-fromto-wrapper.fixed {
    position: fixed;
    z-index: 100000;
    top: 70px;
    width: 100%;
    box-shadow: 0 0 10px #00000029;
    background: transparent linear-gradient(180deg,#f3f3f3,#fff) 0 0 no-repeat padding-box;
}
.rdv2 .mobile-search-fromto-wrapper .nights-container {
    margin-bottom: 0;
}
.rdv2 .portal-search-container .mobile-search-fromto-wrapper .nights {
    background: #E9E9E9 0 0 no-repeat padding-box;
    padding: 5px 15px;
    font-size: 12px;
    display: block;
    border-radius: 50px;
}


.rdv2 .portal-search-container,
.rdv2 .portal-search-container .form-group-inline,
.rdv2 .portal-search-container.gray .js-easypicker-container,
.rdv2 .portal-search-container .form-control {
    background: #FFFFFF;
}

.rdv2 .portal-search-container.gray,
.rdv2 .portal-search-container.gray .form-group-inline,
.rdv2 .portal-search-container.gray .js-easypicker-container,
.rdv2 .portal-search-container.gray .form-control {
    background: #E9E9E9 !important;
}

.rdv2 .portal-search-container .form-group-inline.white,
.rdv2 .portal-search-container .form-group-inline.white .form-control {
    background: #FFFFFF !important;
}

.rdv2 .portal-search-desktop .form-group-inline.white::after,
.rdv2 .portal-search-desktop .form-group-inline.nodashed-border::after {
    border-right: none;
}

/*.rdv2 .portal-search-desktop .js-easypicker-container .portal-search-fromto::before,*/
/*.rdv2 .portal-search-desktop .js-easypicker-container .portal-search-fromto::after {*/
.rdv2 .portal-search-desktop .form-group-inline::after {
    content: "";
    position: absolute;
    top: 50%; /* Start from the middle */
    transform: translateY(-50%); /* Move it up by half of its own height to center it vertically */
    height: 75px; /* Length of the dotted border */
    width: 0; /* No width since it's a vertical border */
    border-right: 1px dashed #BEBEBE; /* Adjust thickness and color as needed */
    right: 0;
}
/*.rdv2 .portal-search-desktop .js-easypicker-container .portal-search-fromto::before {*/
/*    right: 0;*/
/*}*/
/*.rdv2 .portal-search-desktop .js-easypicker-container .portal-search-fromto::after {*/
/*    right: 0;*/
/*}*/


.rdv2 .portal-search__green {
    color: var(--color-primary);
    text-transform: uppercase;
    font-weight: var(--font-default-semibold-weight);
    font-size: 16px;

}
.portal-search-mobile-pullup .portal-search__green {
    padding-top: 15px;
}
.rdv2 .portal-search__black {
    color: black;
    font-weight: var(--font-default-bold-weight);
    font-size: 16px;
}

.rdv2 .portal-search .form-control {
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
    line-height: 44px;
    font-size: 28px;
    border-radius: 0;
}

.rdv2 .portal-search-mobile .portal-search-location{
    padding: 13px 30px;
}

.rdv2 .portal-search-mobile-pullup {
    background: #fff;
    z-index: 99;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    overflow: scroll;
    display: none;
    padding-top: 70px;
    height: 100%;
    width: 100%;
}
.rdv2 .portal-search-mobile-pullup .portal-search-container .form-group {
    /*padding: 0;*/
    padding: 8px 30px 8px 30px;
    border-radius: 0;
}

.rdv2 .portal-search-mobile-pullup .portal-search-container .js-easypicker-container {
    background: transparent linear-gradient(180deg, #F3F3F3 0%, #FFFFFF 100%) 0 0 no-repeat padding-box;
    background-size: 100% 113px; /* Set the gradient height to 50px */
    border-radius: 0;
    display: block;
}
.rdv2 .portal-search-mobile-pullup .portal-search-container .js-easypicker-container .form-group-inline {
    background: none;
}
.rdv2 .portal-search-mobile-pullup .portal-search-container .portal-search-persons {
    background: transparent linear-gradient(180deg, #F3F3F3 0%, #FFFFFF 100%) 0 0 no-repeat padding-box;
    background-size: 100% 113px; /* Set the gradient height to 50px */
    padding-bottom: 110px;
}

.rdv2 .portal-search-mobile-pullup .portal-search-persons .portal-search__black {
    display: flex;
}
.rdv2 .portal-search-mobile-pullup .portal-search-persons .portal-search__person-info {
    width: 50%;
}

.rdv2 .portal-search-mobile-pullup .portal-search-location .portal-search-mobile-collapse {
    min-height: 40px;
}

.rdv2 .portal-search-mobile-pullup .mobile-search-defaults-locations .twitter-ta-location {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
}
.rdv2 .portal-search-mobile-pullup .tt-menu {
    margin-top: 8px;
    padding: 10px 20px;
}
.rdv2 .portal-search-mobile-pullup .tt-suggestion {
    padding: 8px 0;
}
.rdv2 .portal-search-mobile-pullup .mobile-search-someinfo {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
}
.rdv2 .portal-search-persons .portal-search__person-select {
    display: flex;
    justify-content: center;
    align-items: center;
}
.rdv2 .portal-search-persons .portal-search__person-input input {
    cursor: pointer;
}

.rdv2 .portal-search-mobile-pullup .portal-search-persons .portal-search__person-select {
    width: 50%;
}
.rdv2 .portal-search-container input {
    color: #2A2A2A;
    font-weight: bold;
}
.rdv2 .portal-search-container input::placeholder {
    /*color: #669C2F;*/
    font-weight: bold;
}
.rdv2 .portal-search-container .portal-search-persons .portal-search__person-select input {
    outline: 0;
    border: none;
    background: transparent;
    width: 50px;
    text-align: center;
    font-weight: bold;
}
.rdv2 .portal-search-container .portal-search-persons .add, .rdv2 .portal-search-container .portal-search-persons .sub {
    font-size: 22px;
    font-weight: 700;
    box-sizing: border-box;
    border-radius: 50%;
    box-shadow: 0 5px 9px 0 rgba(0, 0, 0, .2);
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 22px;
    cursor: pointer;
}
.rdv2 .portal-search-container .mobile-search-defaults-locations i,
.rdv2 .portal-search-container .mobile-search-someinfo i {
    padding-right: 8px;
    text-align: center;
    font-size: 23px;
    width: 30px;
}
.rdv2 .portal-search-container .minus {
    width: 10px;
    height: 2px;
    background: #A3A3A3;
}
.rdv2 .portal-search-container .plus {
    position: relative;
    width: 10px;
    height: 10px;
}
.rdv2 .portal-search-container .plus::before, .rdv2 .portal-search-container .plus::after {
    content: '';
    position: absolute;
    background-color: #852392;
}
.rdv2 .portal-search-container .plus::before {
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px; /* thickness of the plus sign */
    transform: translateY(-50%);
}

.rdv2 .portal-search-container .plus::after {
    left: 50%;
    top: 0;
    width: 2px; /* thickness of the plus sign */
    height: 100%;
    transform: translateX(-50%);
}

.rdv2 .portal-search-mobile-pullup .js-easypicker-container .portal-search-mobile-collapse{
    padding: 8px 30px 8px 30px;
}
.rdv2 .portal-search-mobile-pullup .portal-search-mobile-filter {
    padding-bottom: 15px;
}
.rdv2 .portal-search-mobile-pullup .js-easypicker-container .portal-search-mobile-filter .portal-search-mobile-collapse {
    display: none;
}
.rdv2 .portal-search-mobile-pullup .js-easypicker-container .portal-search-mobile-filter.show .portal-search-mobile-collapse{
    display: block;
}
.rdv2 .portal-search-mobile-pullup .portal-search-close-container {
    position: relative;
    height: 35px;
    margin-bottom: 7px;
}
.rdv2 .portal-search-mobile-pullup .portal-search-close {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 10;
}

.rdv2 .portal-search-mobile-pullup.open {
    display: block;
}
.rdv2 .portal-search-mobile-pullup .form-group {
    width: 100%;
}
.rdv2 .portal-search-mobile-pullup .portal-search-container {
    display: block;
    width: 100%;
}
.rdv2 .portal-search-mobile-pullup .js-easypicker-container {
    width: 100%;
}
.rdv2 .js-dynamic-slider {
    opacity: 0.57;
}
.rdv2 .js-dynamic-slider.init-show-slides {
    /*display: flex;*/
    justify-content: center;
    display: block;
    overflow: hidden;
    height: 60px;
}
.rdv2 .js-dynamic-slider.init-show-slides .slick-slide {
    display: block!important;
}

.rdv2 .dynamic-slick-inner {
    display: inline-block;
    cursor: grab;
}

.rdv2 .js-dynamic-slider .slick-slide {
    margin: .625rem 0;
}
.rdv2 .js-dynamic-slider .slick-slide .partner-logo {
    display: inline-block;
    margin: 10px;
    height: 60px;
    width: auto;
}
.rdv2 .main-portal .slider .slick-dots {
    display: block !important;
    padding: 20px 0 0 0;
    margin: 0 12px;
    text-align: center;
    border-top: 1px solid #8E8E8E;
    list-style: none;
    white-space: nowrap;
    overflow-x: hidden;
}

.rdv2 .main-portal .slider .slick-dots li {
    padding: 5px;
    margin-left: 13px;
    display: inline-block;
    border: 2px solid #8E8E8E;
    border-radius: 10px;
    height: 12px;
    width: 12px;
    cursor: pointer;
}
.rdv2 .main-portal .slider .slick-dots li.slick-active {
    border: 2px solid var(--color-secondary);
}
.rdv2 .main-portal .slider .slick-dots li:first-child {
    margin-left: 0;
}
.rdv2 .main-portal .slider .slick-dots li button {
    display: none;
}
.rdv2 .main-portal .slider .slick-dots li:after {
    content: '';
    display: block; /* Make it a block element */
    height: 0; /* Set height to 0 to hide the text content */
    overflow: hidden; /* Hide any overflow content */
}



.rdv2 .portal-info-teaser {
    color: #FFFFFF;
    padding: 30px 10px;
}

.rdv2 .info-teasers {
    background-color: #1E1E1E;
}
.rdv2 .info-teasers .video-teaser {
    /*padding: 0;*/
}
.video-teaser .video-js .vjs-big-play-button.vjs-big-play-button {
    height: 200px;
    width: 200px;
    text-align: center;
    background: #FFFFFF;
    opacity: 0.55;
    vertical-align: middle;
}
/*.rdv2 .nav-link {*/
/*    font-weight: 400;*/
/*}*/
.video-teaser .video-js .vjs-big-play-button.vjs-big-play-button:before {
    font-size: 50px;
    /*content: "\EA38";*/
    width: 35px;
    color: var(--color-primary);
}
/*.rdv2 .info-teasers .video-teaser .pimcore_tag_video {*/
/*    min-height: 415px;*/
/*}*/
.rdv2 .info-teasers .portal-info-teaser {
    /*min-height: 370px;*/
    height: auto;
    min-height: auto;
}
.rdv2 .info-teasers .portal-info-teaser.video-js {
    padding: 0;
    margin: 0;
}
.rdv2 .vjs-control-bar {
    padding: 0 15px;
}
.rdv2 .info-teaser-box.video-teaser .vjs-tech {
    /*border-radius: 8px;*/
    object-fit: cover;
    object-position: 50% 50%;
    position: relative;
}
.rdv2 .info-teaser-box.video-teaser video[poster]{
    object-fit: cover;
    object-position: 50% 50%;
}
.rdv2 .video-teaser .vjs-poster {
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
}





.rdv2 .bg-black {
    background-color: #000000;
}
.rdv2 .bg-notsoblack {
    background-color: #1E1E1E;
}

.rdv2 .bg-marble {
    background-color: #1E1E1E;
    background-size: cover;
    /*background: #000000 url('/static/img/beton-bg.jpg') 0 0 no-repeat padding-box;*/

}

/* buttons */
.rdv2 .main-portal .btn {
    text-transform: none;
    border: 1px solid var(--color-primary);
    box-shadow: none;
    min-height: 60px;
    line-height: 60px;
    padding: 0 28px;
    font-size: 23px;
    font-weight: var(--font-default-semibold-weight);
}
button:focus {
    outline: none;
}
/*.rdv2 .main-portal .btn:hover {*/
/*    line-height: 61px;*/
/*}*/
.rdv2 .main-portal .btn-default {
    font-weight: var(--font-default-bold-weight);
}

.rdv2 .main-portal .btn-teaser {
    width: 100%;
    border: none;
}
.main-portal .btn.btn-teaser:hover:not(:disabled):not(.disabled),
.main-portal .btn.btn-teaser:focus:not(:disabled):not(.disabled),
.main-portal .btn.btn-teaser:active:not(:disabled):not(.disabled) {
    border: none;
}

.rdv2 .main-portal .btn.icon-btn .icon-btn__icon.icon-icon_info {
    font-size: 34px;
    top: 6px;
    padding-right: 5px;
}

.rdv2 .main-portal .btn.icon-btn .icon-btn__icon.icon-icon_kostenkontrolle {
    font-size: 30px;
    top: 6px;
    padding-right: 5px;
}

/* buttons on all sites */
/*.btn.btn-default,*/
/*.btn.btn-primary {*/
/*    width: 100%;*/
/*}*/


/*.rdv2 .btn-primary {*/
/*    width: 100%;*/
/*    background: linear-gradient(180deg,#b845c2,#82218f);*/
/*}*/
/*.rdv2 .btn-primary:hover {*/
/*    background: linear-gradient(180deg,#82218f,#b845c2);*/
/*}*/
/*.rdv2 .btn-default {*/
/*    width: 100%;*/
/*    background-color: #FFFFFF;*/
/*    color: #1A6402;*/
/*    text-transform: none;*/
/*    border: 1px solid #1A6402;*/
/*}*/
/*.rdv2 .btn-default:hover {*/
/*    background-color: #FFFFFF;*/
/*    color: #669C2F;*/
/*    border: 1px solid #669C2F;*/
/*}*/
/*.rdv2 .btn.btn-default.btn-filled {*/
/*    background: transparent linear-gradient(180deg, #78BB32 0%, #1A6402 100%) 0 0 no-repeat padding-box;*/
/*    border: none;*/
/*    color: #fff;*/
/*}*/
/*.rdv2 .btn.btn-default.btn-filled:hover {*/
/*    background: transparent linear-gradient(180deg, #1A6402 0%, #78BB32 100%) 0 0 no-repeat padding-box;*/
/*    color: #fff;*/
/*}*/
/* button Exceptions START */
    .account-objekt__body .btn {
        width: auto;
    }
/* button Exceptions END */
/* buttons on all sites END */

.js-expandable__more {
    font-weight: var(--font-default-bold-weight);
    text-transform: uppercase;
}

/* some font family stuff */
.rdv2 .portal-h3 {
    font-size: 40px;
    padding-bottom: 20px;
    line-height: 44px;
    color: #FFFFFF;
    /*display: flex;*/
    /*flex-wrap: wrap;*/
}

.rdv2 .semi-bold {
    font-weight: var(--font-default-bold-weight);
}

/* teasers */
.container--fullwidth {
    padding-left: 0;
    padding-right: 0;
}
.rdv2 .main-portal .content-block {
    margin-top: 0!important;
    /*padding: 0 !important;*/
}
/* town teasers */
.rdv2 .teaser-slider .town-teaser{
    width: 250px;
    height: 400px;
    background-color: initial;
    color: #FFFFFF;
    margin: 0 10px;
    padding: 0;
}
.rdv2 .teaser-slider .town-teaser .town-img {
    width: 250px;
    height: 320px;
    border-radius: 15px;
}
.rdv2 .main-portal .portal-btn {
    /*margin-bottom: 60px;*/
    margin-top: 60px;
}
.rdv2 .teaser-slider .town-teaser .town-teaser__body {
    background: none;
}

.rdv2 .teaser-slider .town-teaser .town-teaser__body a {
    display: flex;
    align-items: center;
}


.town-teaser a {
    padding-left: 0;
}
.town-teaser .town-crest {
    display: inline-block;
    height: 40px;
    width: auto;
    float: left;
    padding-right: 15px;
}
.town-teaser .town-desc {
    float: left;
    display: inline-block;
}
.town-teaser .town-name {
    font-weight: var(--font-default-bold-weight);
    font-size: 22px;
}
/* acco teasers */

.rdv2 .teaser-slider .acco-teaser-rdv2 {
    width: 300px;
    background: transparent;
    color: #FFFFFF;
    height: 100%;
    margin: 0 10px;
    padding: 0;
}
.rdv2 .teaser-slider .acco-teaser-rdv2 .teaser__img {
    border-radius: 10px;
}
.rdv2 .teaser-slider .acco-teaser-rdv2 .teaser__title {
    font-size: 16px;
    font-weight: var(--font-default-bold-weight);
    margin-top: 10px;
    height: 57px;
    overflow: hidden;
}
.rdv2 .teaser-slider .acco-teaser-rdv2 .teaser__pretitle {
    font-size: 14px;
}

.rdv2 .acco-teaser-rdv2 .textline-with-icon {
    padding-left: 30px;
    line-height: 20px;
}
.rdv2 .testclass {
    width: 0;
}
.rdv2 .acco-teasers .teaser-slider .slick-list,
.rdv2 .town-teasers .teaser-slider .slick-list {
    margin: 0;
    padding: 0;
}


/* gradients */
.gray-gradient {
    background: transparent linear-gradient(180deg, #1E1E1E00 0%, #1E1E1E 100%) 0 0 no-repeat padding-box;
}
.black-gradient {
    /*background: transparent linear-gradient(180deg, #1E1E1E 0%, #3C3C3C 100%) 0 0 no-repeat padding-box;*/
    background: transparent linear-gradient(180deg, #000000 0%, #3C3C3C 100%) 0 0 no-repeat padding-box;;
}
.notsoblack-gradient {
    background: transparent linear-gradient(180deg, #1E1E1E 0%, #222222 100%) 0 0 no-repeat padding-box;
}
.notsoblack-reverse-gradient {
    background: transparent linear-gradient(180deg, #000000 0%, #1E1E1E 100%) 0 0 no-repeat padding-box;
}
.green-gradient {
    background: transparent linear-gradient(180deg, var(--color-primary) 0%, var(--color-secondary) 100%) 0 0 no-repeat padding-box;
}
/* footerstuff */
.footer-rdv2 {
    padding-bottom: 0;
    border-top: none;
    margin-top: 40px;
}
.footer-rdv2 .container {
    margin-top: 50px;
    margin-bottom: 50px;
}

.footer-rdv2 .even-more-footer {
    padding-bottom: 35px;
    padding-top: 40px;
    margin-top: 0;
    margin-bottom: 0;
    color: var(--color-secondary);
    background: #1E1E1E;
}

.footer-rdv2 .my-1 {
    font-size: 17px;
    color: #FFFFFF;
    letter-spacing: 0;
}
.footer-rdv2 .list-unstyled .my-1:first-child {
    padding-bottom: 15px;
}
.footer-rdv2 .my-1 .h6 {
    font-size: 18px;
    line-height: 23px;
    font-weight: var(--font-default-bold-weight);
    padding-bottom: 1px;
    text-transform: uppercase;
    color: var(--color-secondary);
    letter-spacing: 2px;
    display: inline-block;
}
.footer-rdv2 .my-1 .h6:hover {
    padding-bottom: 0;
}
.footer-rdv2 .btn.btn-circle {
    border: none;
    height: 90px;
    width: 90px;
    font-size: 60px;
}


.rdv2 .main-portal .btn.icon-btn .icon-btn__icon, .main-portal .btn>.btn__icon {
    position: relative;
    top: 4px;
    line-height: 1px;
    font-size: 28px;
    padding-right: 10px;
}
.rdv2 .clock-svg-container {
    margin: 30px auto 30px auto;
    position: relative;
    width: 40px;
    height: 40px;
}

.rdv2 .clock-svg-container i {
    position: absolute;
    color: #FFFFFF;
    top: 0;
    left: 0;
    font-size: 40px;
}


/*video stuff*/
.rdv2 .text-video .video-teaser {
    padding-top: 25px;
    margin: -15px;
}
.rdv2 .text-video .row {
    margin-right: -20px;
    margin-left: -20px;
}
.rdv2 .text-video .row .portal-video {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
}
.rdv2 .text-video .btn {
    /*width: 100%;*/
    margin-top: 20px;
}
.rdv2 .text-video h4 {
    padding-top: 30px;
}

.rdv2 .portal-video .pimcore_tag_video {
    /*width: 100%;*/
    /*height: 100%;*/
}
.rdv2 .js-video-js.video-js.video-js-portal-video {
    /*width: 100%;*/
    /*height: auto;*/
}


/* Twitter typeahead stuff */
.rdv2 .portal-search-container .twitter-typeahead .tt-menu .tt-suggestion {
    display: flex;
    align-items: center;
    /*word-wrap: break-word;*/
    word-break: break-word;
    /*border-bottom: 1px solid #e9e9e9;*/
    /*padding-bottom: 10px;*/
    /*padding-top: 10px;*/
}
.rdv2 .portal-search-container .twitter-typeahead .tt-menu .tt-suggestion .icon {
    padding-right: 8px;
}
.rdv2 .portal-search-container .twitter-typeahead .tt-menu .tt-suggestion:not(:last-child) {
    border-bottom: 1px dashed #bebebe;
}

.rdv2 .portal-search-container .twitter-typeahead .tt-dataset {
    font-size: 24px;
}
.rdv2 .portal-search-container .twitter-typeahead .tt-dataset:not(:last-child) {
    margin-bottom: 15px;
}

.rdv2 .portal-search-container .twitter-typeahead .tt-dataset h5 {
    color: var(--color-primary);
    padding-bottom: 15px;
}


.rdv2 .portal-search-mobile-pullup .mobile-button-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: #fff;
    height: 70px;
    width: 100%;
    box-shadow: 0 0 10px #00000029;
    justify-content: right;
    padding: 0 10px;
}
.rdv2 .portal-search-mobile-pullup .mobile-button-bar .mobile-filter-reset {
    width: 50%;
    font-size: 14px;
    font-weight: 600;
    color: #969696;
    text-decoration: underline;
    text-align: center;
}
.rdv2 .portal-search-mobile-pullup .mobile-button-bar .mobile-filter-next,
.rdv2 .portal-search-mobile-pullup .mobile-button-bar .mobile-filter-submit {
    width: 50%;
    border-radius: 100px;
    height: 50px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
}
.rdv2 .portal-search-mobile-pullup .mobile-button-bar .mobile-filter-submit {
    background: transparent linear-gradient(180deg, #B442BE 0%, #852392 100%) 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: inset 0px 0px 6px #00000029;
}

.rdv2 .portal-search-mobile-pullup .mobile-button-bar .mobile-filter-next {
    border: 1px solid #852392;
    color: #852392;
    background: #fff;
}

.rdv2 .portal-search-mobile-pullup .mobile-button-bar .mobile-filter-submit .icon {
    padding-right: 10px;
}

.rdv2 .portal-search-mobile-collapse .portal-search__green {
    line-height: 24px;
}
/*.rdv2 .mobile-search-fromto-wrapper .portal-search-mobile-collapse .portal-search__green {*/
/*    display: block;*/
/*}*/
.rdv2 .mobile-search-fromto-wrapper .footer-info {
    display: inline-block;
    float: right;
    font-size: 12px;
    text-align: right;
    color: #1E1E1E;
    font-weight: 500;
    text-transform: none;
}
.rdv2 .mobile-search-fromto-wrapper .footer-info .min-nights {
    display: inline-block;
    border: 2px solid var(--color-secondary);
    height: 24px;
    width: 24px;
    border-radius: 50%;
    line-height: 19px;
    text-align: center;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}


.mt-40h {
    margin-top: 40vh;
}


/* bootstrap overrides */
.dropdown-item {
    font-weight: var(--font-default-weight);
}

/* icon overrides */
.acco-teasers .textline-with-icon>.textline-with-icon__icon {
    top: 11px;
}

small, .small {
    font-weight: var(--font-default-weight);
}

.footer-rdv2 .list-social {
    text-align: center;
}

.main-portal .textline-with-icon a.pimcore_glossary {
    color: var(--color-secondary);
}

/*Acco Search tmp css styles (until search has bee reworked)*/
/*.js-filter-form .easepick-wrapper {*/
/*    !*border-radius: 40px;*!*/
/*    border: 2px solid var(--color-primary);*/
/*    z-index: 100;*/
/*}*/
/*.js-filter-form .js-easypick-wrapper {*/
/*    top: 60px;*/
/*}*/

/*.rdv2 .js-filter-form .js-easypicker-container .icon-close {*/
/*    box-shadow: 0 5px 9px 0 rgba(0,0,0,.2);*/
/*    !*box-shadow: none;*!*/
/*    top: 15px;*/
/*    right: 3px;*/
/*    font-size: 12px;*/
/*    padding: 4px;*/
/*}*/
