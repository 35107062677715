.fancy-teasers-container {
    @media screen and (max-width: 767px) {
        position:relative;
        margin:0 -40px;
        padding-bottom:4.5rem;
    }
}

.fancy-teaser {
    position: relative;
}
.fancy-teaser__body {
    position: relative;
    line-height:1.75;
    padding:5rem 0 1.5rem 1.25rem;
    @media screen and (min-width: 768px) {
        width: 350px;
        padding:6.25rem 0 0 0;
    }
}
.fancy-teaser__body:before {
    content:'';
    position: absolute;
    left:4rem;
    top:-1.25rem;
    width: 3px;
    height: 80px;
    background-color: var(--color-primary);

    @media screen and (min-width: 768px) {
        top:-3rem;
        left:5.5rem;
        height: 120px;
    }
}
.fancy-teaser__headline {
    position: relative;
    margin-bottom:1rem;
    line-height:1.2;
}
.fancy-teaser__headline > .icon{
    position: absolute;
    left: -2rem;
    top: -1.5rem;
    font-size: 3.75rem;
    color: #78aa2d;
    opacity: .15;

    @media screen and (min-width: 768px) {
        left: -4rem;
        top: -2.75rem;
        font-size: 6.75rem;
    }
}



/* teaser 1 */
@media screen and (min-width: 768px) {
    .fancy-teaser--1 {
        z-index:5;
        margin:0 -140px 90px 0;
    }
}



/* teaser 2 */
@media screen and (min-width: 768px) {
    .fancy-teaser--2 {
        margin:120px 0 0 50px;
    }
    .fancy-teaser--2 .fancy-teaser__body {
        margin-left:62%;
    }
}



/* teaser 3 */
.fancy-teaser--3 .fancy-teaser__body {
    @media screen and (min-width: 1400px) {
        margin-left:6rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1399px) {
        width:auto;
        margin-left:2rem;
    }
}
@media screen and (min-width: 768px) {
    .fancy-teaser--3 .fancy-teaser__img {
        margin-left:-60px;
        z-index:5;
    }
}



/* mobile slider */
@media screen and (max-width: 767px) {
    .fancy-teaser-arrow-holder {
        position: absolute;
        width: 100px;
        height:55px;
        bottom:.5rem;
        right:3.125rem;
    }
}
