@media screen and (max-width: 767px) {
    .mobile-navbar {
        position: fixed;
        overflow: scroll;
        top:50px;
        left:0;
        right:0;
        bottom:0;
        padding:2rem 2.5rem 4rem 2.5rem;
        background: #fff;
        transform: translateX(101%);
        transition: all .15s ease;
    }
    .mobile-navbar.is-open {
        transform: translateX(0%);
    }
    header .mobile-navbar .nav-link.nav-link {
        padding: .5rem 0;
        /*font-size: 1rem;*/
        position: relative;
    }
    /* BURGER START */
    #burger1 {
        width: 40px;
        height: 35px;
        position: relative;
        /*margin: 50px auto;*/
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
    }
    #burger1 span {
        display: block;
        position: absolute;
        height: 5px;
        background: #fff;
        width: 100%;
        border-radius: 9px;
        opacity: 1;
        right: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }
    #burger1 span:nth-child(1) {
        /*width: 35px;*/
        top: 0px;
    }
    #burger1 span:nth-child(2) {
        top: 11px;
    }
    #burger1 span:nth-child(3) {
        /*width: 35px;*/
        top: 22px;
    }
    header .navbar-toggler {
        padding: 10px;
    }
    .navbar-toggler.navbar-btn-menu.active #burger1 span:nth-child(1) {
        top: 11px;
        /*right: 5px;*/
        right: 0;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    .navbar-toggler.navbar-btn-menu.active #burger1 span:nth-child(2) {
        opacity: 0;
        right: 60px;
    }
    .navbar-toggler.navbar-btn-menu.active #burger1 span:nth-child(3) {
        top: 11px;
        /*right: 5px;*/
        right: 0;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
    }
    /* BURGER END */

    /* right */
    header .navbar-right__item {
        position: relative;
        display: inline-block;
        margin:0;
        padding:.75rem .25rem .75rem 1.875rem;
        /*font-size:1rem;*/
    }
    header .navbar-right__item>.icon-chevron, header .navbar-right__item>.icon-heart,
    header .navbar-right__item .icon {
        position: absolute;
        left:0;
        /*top: .75rem;*/
        top: 17px;
        font-size: 1.125rem;
    }
    header .navbar-right__list-item .icon {
        font-size: 1.125rem;
    }
    header .navbar-right__list-item .icon.icon-black {
        width: 1.125rem;
    }
    header .navbar-right__item--right-icon {
        /*padding:.5rem 1.25rem .5rem 0;*/
    }
    header .navbar-right__item--right-icon>.icon-chevron {
        position: relative;
        top: 0;
        left: 10px;

        font-size: .75rem;
        /*left:auto;*/
        right:0;
        /*top: .875rem;*/
        transform: rotate(90deg);
    }

    header.dashboard .icon-menu {
        color: white;
    }
    header.dashboard #burger1 span {
        background: white;
    }
    header.dashboard .navbar {
        margin: 0;
        height: 100%;
    }
    header.dashboard .navbar .container-fluid {
        /*height: 100%;*/
    }
    header.dashboard .navbar .container-fluid .row.no-gutters{
        height: 100%;
    }
    header.dashboard .navbar .container-fluid .row.no-gutters .col-12{
        height: 100%;
    }
    /*header.dashboard .navbar-brand{*/
    /*    padding-top: 0.5rem;*/
    /*    padding-bottom: 0.5rem;*/
    /*}*/
    header.dashboard .navbar-toggler,
    header.dashboard .m-backbutton{
        /*height: 100%;*/
        height: 70px;
        padding: 10px;
        /*padding-top: 0.7rem;*/
        border-left: 1px solid white;
        border-radius: unset;
    }
    header.dashboard .m-backbutton {
        display: inline-block;
        float: right;
        width: 55px;
        padding-top: 16px;
    }
    header.dashboard .navbar-toggler {
        width: 53px;
        text-align: center;
    }

    header.dashboard .m-backbutton{
        padding-right: 1rem;
        padding-left: 1rem;
    }
    header.dashboard .mobile-navbar {
        color: black;
    }
    header .nav-item {
        font-weight: var(--font-default-weight);
    }
    header .nav-item.active  {
        font-weight: var(--font-default-medium-weight);
    }

    #collapseProfile a.active {
        font-weight: var(--font-default-medium-weight);

    }

}
