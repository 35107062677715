.account-calendar {
    position: relative;
    border-radius: 10px;
    border: 1px solid #C7C7C7;
    overflow: hidden;
}

@media screen and (max-width: 767px) {
    .account-layout__content--cal {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.account-calendar__left {
    border-right: 1px solid #C7C7C7;
}
.account-calendar__left-item {
    display: block;
    height:90px;
    font-size: .75rem;
    line-height:1.33;
    padding:.625rem 1rem .3125rem 1rem;
    border-bottom: 1px solid #C7C7C7;

    @media screen and (max-width: 767px) {
        word-break: break-all;
        font-size: .75rem;
        padding:.25rem .375rem .25rem 1rem;
        height:120px;
    }

    @media screen and (max-width: 360px) {
        padding:.25rem .375rem .25rem 1rem;
    }
}

.account-calendar__left-item:last-of-type {
    border-bottom:0;
}
.account-calendar__left-item--spacer.account-calendar__left-item--spacer{
    height:55px;
}



.account-calendar__months {
    position: relative;
    width:100%;
    overflow-x: scroll;
    overflow-y: hidden;
}
.account-calendar__months::-webkit-scrollbar {
    height: 10px;
    cursor: pointer;
}
.account-calendar__months::-webkit-scrollbar-thumb {
    background: rgba(50,50,50,0.3);
}
.account-calendar__months-month {
    display: inline-block;
    color: #8F8F8F;
    font-size:.875rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;
    text-align: center;
    float:left;
    padding:.375rem 0;
    height:55px;
    border-bottom: 1px solid #C7C7C7;
    @media screen and (max-width: 767px) {
        font-size:.75rem;
    }
}
.account-calendar__months-month:before {
    position: absolute;
    left:-1px;
    top:0;
    content:'';
    width:1px;
    height:2000px;
    border-left: 1px solid #C7C7C7;
}
.account-calendar__months-month--active {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    color:var(--color-primary);
}
@supports (font-variation-settings: "wdth" 315) {
    .account-calendar__months-month--active {
        font-family: var(--font-default-vf);
    }
}
.account-calendar__months-monthname {
    display: block;
    text-align: center;
    overflow: hidden;
}
.account-calendar__months-day {
    float:left;
    width: 1rem;
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
    font-size:11px;
    letter-spacing:-.5px;
    text-indent: 0;
    text-align: center;
    display: inline-block;
    border-width:0 1px;
}
@supports (font-variation-settings: "wdth" 315) {
    .account-calendar__months-day {
        font-family: var(--font-default-vf);
    }
}
.account-calendar__months-scroll {
    position: relative;
    width:100%;
    overflow: hidden;
}
.account-calendar__months-scroll:after {
    content:'';
    position: absolute;
    right:0;
    top:0;
    bottom:0;
    width: 1px;
    background:#C7C7C7;
}
.account-calendar__months-scroll-inner {
    display: block;
    width: 100%;
    height:55px;
    position: relative;
}
.account-calendar__room {
    height:90px;
    border-bottom: 1px solid #C7C7C7;
    padding-top:.5rem;
    position: relative;
    @media screen and (max-width: 767px) {
        height:120px;
    }
}
.account-calendar-dayitem{
    display: block;
    background-color: #C7C7C7;
    position: absolute;
    top:0;
    height:1rem;
    width:1.5rem;
    line-height:1px;
    z-index:10;
    cursor: pointer;
    border:1px solid #fff;
    border-width:0 1px;
}
.account-calendar-dayitem>a{
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
}
.account-calendar-dayitem.is-hovered,
.account-calendar-dayitem:hover {
    background-color: #a1a1a1;
}
.account-calendar-dayitem--locked{
    background-color: #919191;
}
.account-calendar-dayitem--waiting{
    background-color: #FFA600;
}
.account-calendar-dayitem--payment{
    background-color: #FFDE30;
}
.account-calendar-dayitem--success{
    background-color: #B5EC61;
}
.account-calendar-dayitem--cancel{
    background-color: #F55B5B;
}
.account-calendar-dayitem--waiting.is-hovered,
.account-calendar-dayitem--waiting:hover{
    background-color: #e08900;
}
.account-calendar-dayitem--payment.is-hovered,
.account-calendar-dayitem--payment:hover{
    background-color: #e2c12e;
}
.account-calendar-dayitem--success.is-hovered,
.account-calendar-dayitem--success:hover{
    background-color: #7ca854;
}
.account-calendar-dayitem--cancel.is-hovered,
.account-calendar-dayitem--cancel:hover{
    background-color: #d25757;
}


.account-calendar__room-row {
    position: absolute;
    left:0;
    right:0;
    top:.5rem;
}
.account-calendar__room-row + .account-calendar__room-row {
    top:2rem;
}
.account-calendar__room-row + .account-calendar__room-row + .account-calendar__room-row {
    top:3.75rem;
}

@media screen and (max-width: 767px) {
    .account-calendar {
        margin:0 -31px;
    }
}
@media screen and (max-width: 767px) and (orientation: portrait) {
    .account-calendar {
        margin:0;
        width:750px;
    }
    .account-calendar--scroll {
        overflow-x: scroll;
        margin:0 0 0 -32px;
    }
}



/* modal */
.booking-detail-modal button.close {
    position: absolute;
    right:0;
    top:0;
    z-index:10;
    width:44px;
    height:44px;
    text-align: center;
    background: rgba(255,255,255,.9);
    opacity: .7;
}
.booking-detail-modal button.close>.icon {
    position: relative;
    top:.125rem;
    font-size: 1.125rem;
}

