.reservation_filter {
    width: 100%;
    position: relative;
    border-radius: 10px;
    /*border: 1px solid #c7c7c7;*/
    /*padding: 1.5rem 1.5rem 1.0rem 1.5rem;*/
    /*margin-top: 1.5rem;*/
}
.reservation_filter .custom-checkbox {
    margin-right: 20px;
}
a.not-a-btn {
    /*padding: 0.875rem 2.5rem;*/
    padding: 0 0 0 15px;
    letter-spacing: 2px;
    font-size: 16px;
    /*text-transform: uppercase;*/
    font-weight: bold;
    color: #82218f;
}
@media (min-width: 768px) {
    .dashboard .col-db-2 {
        flex: 0 0 16.66667% !important;
        max-width: 16.66667% !important;
    }
    .dashboard .col-db-4 {
        flex: 0 0 33.33333% !important;
        max-width: 33.33333% !important;
    }
    .dashboard .col-db-6 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
}

@media screen and (max-width: 767px) {
    .account-teaser .my-2 a.btn {
        width: 100%;
    }
    .other-btns a.not-a-btn:first-child {
        padding-left: 0;
    }
    .res-action-buttons {
        text-align: center;
    }
    .other-btns {
        text-align: center;
    }
}
