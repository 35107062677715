.disabled-datepicker input {
    cursor: not-allowed;
    pointer-events: none;
}

.ui-datepicker {
    border: 0;
    box-shadow: 0 0 30px 0 rgba(0,0,0,.15);
    background-color: #fff;
    border-radius: 10px;
    z-index: 1150 !important; /* higher as modal */

    @media screen and (min-width: 768px) {
        width:350px;
    }
}
.ui-datepicker td {
    padding-left: 0;
    padding-right: 0;
}
.ui-datepicker td a,
.ui-datepicker td span {
    padding: .2em .4em;
}

.ui-datepicker.is-loading {
    /*opacity: .2;*/
    /*pointer-events: ;*/
}
.ui-datepicker table .ui-state-active {
    background: var(--color-tertiary) !important;
    /*background: #B442BE;*/
    color: #fff;
    border-radius: 50px;
}

.ui-datepicker table .is-loading > a,
.ui-datepicker table .is-loading > span {
    opacity: .2;
}
.ui-datepicker table .is-arrival {
    opacity: 1;
}
.ui-datepicker table .is-arrival > a,
.ui-datepicker table .is-arrival > span {
    background: var(--color-tertiary);
    color: #fff;
}
.ui-datepicker .ui-state-default {
    /*background: var(--color-primary-light);*/
}
.ui-datepicker .ui-state-disabled {
    opacity: .4 !important;
    background: #fff !important;
}
.ui-datepicker .ui-state-disabled>a,
.ui-datepicker .ui-state-disabled>span {
    color: #9e9e9e !important;
    /*background: var(--color-danger-light) !important; #B442BE*/
    background: #ffffff !important;
    cursor:not-allowed !important;
}
.ui-datepicker table .is-departure {
    opacity: 1;
}
.ui-datepicker table .is-in-range {
    opacity: 1;
}
.ui-datepicker table .is-in-range > a,
.ui-datepicker table .is-in-range > span {
    background: var(--color-primary-light);
    /*background: var(--color-tertiary);*/
}
.ui-datepicker table .is-in-range.ui-state-disabled > span {
    color: #999 !important;
}

.ui-datepicker table .is-booked {
    opacity: 1;
}
.ui-datepicker table .is-booked > a,
.ui-datepicker table .is-booked > span {
    background: var(--color-danger-light);
    cursor:not-allowed;
}
.ui-datepicker table .is-departure > a,
.ui-datepicker table .is-departure > span {
    background: var(--color-tertiary) !important;
    border-radius: 50px;
    color: #fff;
}


.ui-datepicker table .ui-datepicker-today {
    opacity: 1;
}
.ui-datepicker table .ui-state-hover,
.ui-datepicker td a:hover {
    background: var(--color-tertiary);
    color: #fff;
}



/* arrows */
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
    text-align: center;
    line-height: 1.8em;
    cursor: pointer;
    top: 2px;
    color: var(--color-secondary);
}
.ui-datepicker-next:hover,
.ui-datepicker-prev:hover {
    text-decoration: none;
}

.ui-datepicker-prev:before {
    font-family: iconfont;
    content: var(--icon-chevron);
}

.ui-datepicker-prev {
    transform:scaleX(-1);
}
.ui-datepicker-next:after {
    font-family: iconfont;
    content: var(--icon-chevron);
}