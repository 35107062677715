.ical-item {
    position: relative;
    padding:.75rem 0 1rem 0;
    margin-bottom: .5rem;
    border-bottom: 1px solid #C7C7C7;

    @media screen and (min-width: 768px) {
        margin:0 -2rem 0 -2rem;
        padding:1rem 2rem;
    }
}