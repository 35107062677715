
/*md*/
@media screen and (min-width: 768px) {
    .rdv2 .main-portal .btn-primary {
        width: 100%;
    }
    .rdv2 .main-portal .btn-default {
        width: 100%;
    }
    header .navbar-right__item .watchList-count {
        top: 24px;
    }
    .rdv2 .btn.btn-block {
        width: 100%;
    }
    .rdv2 .header-light {
        height: 90px;
        background: rgba(30, 30, 30, 0.37);
    }
    .rdv2 .navbar-brand {
        width: 100%;
    }
    .rdv2 .navbar-brand-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .rdv2 .navbar-brand-container {
        height: 100%;
        width: 250px;
        padding: 20px;
    }
    .rdv2 .portal-search-mobile-pullup {
        padding-top: 90px;
    }
    /*.rdv2 .info-teasers .video-teaser .pimcore_tag_video {*/
    /*    min-height: 560px;*/
    /*}*/
    /*.rdv2 .main-img-container {*/
    /*    padding-top: 90px;*/
    /*}*/
    .rdv2 header.dashboard .navbar>.container-fluid {
        padding-right: 48px;
    }
    .rdv2 .navbar-right {
        display: flex;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        padding-bottom: 20px;
    }
    .rdv2 .navbar-right a span:not(.js-watchList-count){
        display: none;
    }
    /*header .navbar-right__item .watchList-count {*/
    /*    top: 25px;*/
    /*}*/
    .footer-rdv2 .list-social {
        position: relative;
        left: -15px;
        text-align: left;
    }
}

/*lg*/
@media screen and (min-width: 992px) {
    /*.rdv2 .main-portal .btn-primary {*/
    /*    width: 100%;*/
    /*}*/
    /*.rdv2 .main-portal .btn-default {*/
    /*    width: 100%;*/
    /*}*/
    .rdv2 .main-portal h2 {
        font-size: 30px;
    }
    .rdv2 .main-portal h4 {
        font-size: 60px;
    }
    .rdv2 .teaser-slider .town-teaser {
        width: 425px;
        height: 650px;
    }
    .rdv2 .teaser-slider .town-teaser .town-img {
        width: 425px;
        height: 550px;
        border-radius: 15px;
    }
    .town-teaser {
        font-size: 24px;
    }
    .town-teaser .town-crest {
        height: 70px;
    }
    .town-teaser .town-name {
        font-size: 30px;
    }

    .rdv2 .main-portal .main-img-container h1 {
        font-weight: var(--font-default-semibold-weight);
    }
    .rdv2 .main-img__overlay--search {
        /*top: 36%;*/
        /*top: calc(50% - 125px) !important;*/
        /*top: calc(40% - 216px);*/
    }

    .rdv2 .main-portal h3 {
        font-size: 28px;
    }
    /*.rdv2 .js-dynamic-slider .slick-slide .partner-logo {*/
    /*    height: 100px;*/
    /*    margin-left: 20px;*/
    /*    margin-right: 20px;*/
    /*}*/

    .rdv2 .portal-search {
        margin-top: 30px;
    }

    /*.rdv2 .main-img__overlay--search {*/
    /*    top: 28%;*/
    /*}*/
    .rdv2 .main-img__overlay--search .container{
        position: relative;
    }
    .rdv2 .portal-search {
        height: 120px;
    }
    .rdv2 .portal-search-click-arrow i {
        top: 33px;
    }
    .rdv2 .portal-search__black {
        display: inline-block;
    }
    .rdv2 .portal-search .form-control {
        font-size: 19px;
    }
    .rdv2 .portal-search-container .portal-search-location {
        width: 30%;
    }
    .rdv2 .main-portal .main-img-container h1 {
        font-size: 80px;
    }
    .rdv2 .teaser-slider .acco-teaser-rdv2 {
        width: 420px;
    }
    .rdv2 .teaser__body {
        font-size: 20px;
    }
    /*.rdv2 .teaser__body .textline-with-icon i {*/
    /*    top: 17px;*/
    /*}*/

    .rdv2 .teaser-slider .acco-teaser-rdv2 .teaser__pretitle {
        font-size: 22px;
    }
    .rdv2 .teaser-slider .acco-teaser-rdv2 .teaser__title {
        font-size: 24px;
        height: 85px;
    }
    .main-portal {
        font-size: 24px;
    }
    .rdv2 .text-video .video-teaser {
        padding: 0 35px 0 15px;
        margin: auto;
    }
    .rdv2 .main-portal .text-video .btn {
        margin-top: 80px;
        /*width: auto;*/
    }


    .rdv2 .portal-info-teaser .main-portal .btn-teaser {
        position: absolute;
        width: calc(100% - 30px);
        bottom: 0;
        left: 0;
        margin: 15px;
    }
    /*.rdv2 .info-teasers .video-teaser .pimcore_tag_video {*/
    /*    min-height: 660px;*/
    /*}*/
    .rdv2 .main-portal .btn {
        font-size: 35px;
        min-height: 90px;
        line-height: 90px;
        padding: 0 30px;
        /*padding: 0;*/
    }
    .rdv2 .main-portal .btn.icon-btn .icon-btn__icon.icon-icon_info {
        font-size: 44px;
        padding-right: 10px;
    }
    .rdv2 .main-portal .btn.icon-btn .icon-btn__icon.icon-icon_kostenkontrolle {
        font-size: 40px;
        padding-right: 10px;
    }
    .rdv2 .main-portal .btn.icon-btn .icon-btn__icon, .rdv2 .main-portal .btn>.btn__icon {
        font-size: 38px;
    }
    /*.rdv2 .info-teasers .video-teaser .pimcore_tag_video {*/
    /*    height: 560px;*/
    /*}*/
    .desktop-more-footer .clock-svg-container {
        display: inline-block;
        margin: 0;
        top: 15px;
    }
    .desktop-more-footer .clock-svg-container i {
        font-size: 40px;
    }
    .desktop-more-footer .list-inline {
        display: inline-block;
    }
    /*.rdv2 .video-teaser {*/
    /*    padding: 0;*/
    /*}*/
    .footer-rdv2 {
        /*border-top: 3rem solid #fff;*/
        padding: 3rem 0 0 0;
    }
    .footer-rdv2 .even-more-footer {
        padding-top: 7px;
        max-width: 100%;
        width: 100%;
        height: 75px;
    }
    .footer-rdv2 .btn.btn-circle {
        height: 60px;
        width: 60px;
        font-size: 40px;
    }
    .footer-rdv2 .my-1 {
        font-size: 20px;
    }
    .footer-rdv2 .my-1 .h6 {
        font-size: 24px;
    }
    .rdv2 .main-portal .btn.btn-default {
        display: inline-block;
        /*width: auto;*/
    }
    .rdv2 .portal-h3 {
        font-size: 60px;
        line-height: 64px;
        font-weight: var(--font-default-semibold-weight);
    }
    .rdv2 .black-info-box .blackbox-link {
        color: #B442BE;
        font-size: 36px;
    }
    .rdv2 .black-info-box .portal-h3 {
        font-size: 50px;
    }
    .rdv2 .black-info-box .portal-h3,
    .rdv2 .portal-info-teaser .portal-h3 {
        font-weight: var(--font-default-medium-weight);
    }
    .rdv2 .black-info-box .portal-h3.semi-bold,
    .rdv2 .portal-info-teaser .portal-h3 .semi-bold {
        font-weight: var(--font-default-semibold-weight);
    }
    .easepick-wrapper {
        border-radius: 30px;
    }
    /*.rdv2 .content-block--with-background.acco-teasers {*/
    /*    padding-bottom: 0;*/
    /*}*/
    .rdv2 .js-easypicker-container .icon-close {
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
    .rdv2 .js-easypicker-container .icon-close:hover {
        color: #fff;
        background-color: #1f232a;
        border-color: #1f232a;
    }
    .rdv2 .portal-search-container .portal-search-fromto,
    .rdv2 .portal-search-container .portal-search-persons {
        width: 20%;
    }
    .rdv2 .portal-search-container input.form-control:not(.tt-hint) {
        /*margin-top: 22px;*/
    }

    .rdv2 .portal-search-container .portal-search-location, .rdv2 .portal-search-container .portal-search-persons, .rdv2 .portal-search-container .portal-search-fromto {
        padding: 15px 30px;
        /*position: relative;*/
    }
    .rdv2 .portal-search-container .portal-search-location .portal-search__black,
    .rdv2 .portal-search-container .portal-search-persons .portal-search__black,
    .rdv2 .portal-search-container .portal-search-fromto .portal-search__black {
        padding-top: 15px;
        /*position: absolute;*/
        /*left: 0;*/
        /*bottom: 0;*/
        /*padding: inherit;*/
    }

    .rdv2 .portal-search-container .js-easypicker-container {
        width: 50%;
    }
    .rdv2 .portal-search-container .portal-search-persons .portal-search__person-select {
        position: absolute;
        background: #fff;
        height: 104px;
        border-radius: 100px;
        width: calc(100% + 75px);
        left: 0;
        top: 118px;
    }
    .rdv2 .portal-search-container .portal-search-persons .portal-search__person-label {
        padding-right: 20px;
        font-size: 12px;
        font-weight: bold;
    }
    .rdv2 .js-easypicker-container .icon-close {
        top: 37px;
        right: 8px;
        font-size: 16px;
        box-shadow: 0 5px 18px 0 rgba(0, 0, 0, .2);
        padding: 10px;
    }
    .rdv2 .portal-search-container .twitter-typeahead .tt-menu {
        top: 69px !important;
        border-radius: 50px !important;
        width: calc(100% + 52px) !important;
        left: -34px !important;
        padding: 35px;
    }
    /* reintroduce button focus effect */
    button:focus {
        outline: 5px auto -webkit-focus-ring-color;
    }
}

/* hacky xl needed until autofixer is fixed in gulp. because media queries get merged and then are in the wrong order */
@media screen and (min-width: 1199px) {
    .rdv2 .portal-h3 {
        font-size: 38px;
        line-height: 42px;
    }
    .rdv2 .main-portal .info-teasers {
        font-size: 23px;
    }
    .rdv2 .main-portal .info-teasers .btn {
        font-size: 20px;
        padding: 0 30px;
    }
    .rdv2 .black-info-box .portal-h3 {
        font-size: 38px;
        line-height: 42px;
    }
    .rdv2 .portal-search-container .portal-search-persons .portal-search__person-label {
        font-size: 20px;
    }
    .rdv2 .portal-search .form-control {
        font-size: 28px;
    }
    .rdv2 .portal-search__black {
        font-size: 28px;
    }

    .rdv2 .portal-search-container .portal-search-persons .portal-search__black,
    .rdv2 .portal-search-container .portal-search-fromto .portal-search__black {
        padding-top: 5px;
    }
    .rdv2 .portal-search-container .portal-search-location .portal-search__black {
        padding-top: 0;
    }
}

/*@media screen and (min-width: 1256px) {*/
/*    .rdv2 .main-img__overlay--search {*/
/*        top: calc(50% - 216px);*/
/*    }*/
/*}*/

/*xl*/
@media screen and (min-width: 1200px) {
    .rdv2 .black-info-box .black-box {
        margin: 30px 0 65px 0;
        padding: 15px;
    }
    .rdv2 .content-block.content-block--with-background.black-info-box {
        padding-bottom: 2rem;
    }
    .rdv2 .black-info-box .blackbox-link {
        /*padding-bottom: unset;*/
    }
    .rdv2 .btn-primary {
        width: auto;
    }
    .rdv2 .btn-default {
        width: auto;
    }
    .town-teasers .btn-default, .acco-teasers .btn-default, .text-video .btn-default {
        width: auto !important;
    }
    .rdv2 .navbar-right a span:not(.js-watchList-count){
        display: block;
    }
    .rdv2 .black-info-box .black-box.notsoblack-gradient {
        background: none;
    }

    .rdv2 .navbar-right {
        padding-bottom: 0;
    }
    .rdv2 .info-teasers {
        padding: 24px 15px;
    }
    .rdv2 .info-teasers .portal-info-teaser {
        height: 100%;
        min-height: 560px;
        position: relative;
        padding-bottom: 130px;
    }
    .rdv2 .video-teaser .vjs-tech {
        border-radius: 8px;
    }
    .rdv2 .main-portal .info-teasers .btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% - 40px);
        margin: 20px
    }
    .rdv2 .info-teasers .pimcore_tag_video {
        height: 100%;
    }
    .rdv2 .main-portal .info-teasers .container.container--wide {
        max-width: 95.625rem;
        padding-left: 15px;
        padding-right: 15px;
    }
    .rdv2 .portal-info-teaser {
        border-radius: 8px;
    }
    .rdv2 .portal-info-teaser .vjs-poster {
        border-radius: 8px;
    }
    .rdv2 .portal-info-teaser .video-js {
        border-radius: 8px;
    }
    /* smaller font sizes as we jump to xxl */

}
/*xxl + 100*/
@media screen and (min-width: 1500px) {
    .rdv2 .portal-h3 {
        font-size: 50px;
        line-height: 54px;
    }
    .rdv2 .main-portal .info-teasers {
        font-size: 24px;
    }
    .rdv2 .main-portal .info-teasers .btn {
        font-size: 35px;
        padding: 0 30px;
    }
    .rdv2 .black-info-box .portal-h3 {
        font-size: 50px;
        line-height: 54px;
    }
}
/*xxl + 300*/
@media screen and (min-width: 1700px) {
    .rdv2 .portal-h3 {
        font-size: 60px;
        line-height: 64px;
    }
}
/*xxl + 300*/
@media screen and (min-width: 2100px) {
    .rdv2 .main-portal .info-teasers .container.container--wide,
    .rdv2 .main-portal .container.container--wide {
        max-width: 1920px;
    }
}



/* LEGACY overrides and height stuff */
@media screen and (max-height: 639px) {
    .rdv2 .main-img__overlay--partners {
        display: none;
    }
    /*.rdv2 .main-img__overlay--search {*/
    /*    top: 26%;*/
    /*}*/
}

/*@media screen and (min-width: 992px) and (min-height: 1100px) {*/
/*    .rdv2 .main-img__overlay--search {*/
/*        !*top: 36%;*!*/
/*        !*top: calc(50% - 125px) !important;*!*/
/*        top: calc(50% - 216px);*/
/*    }*/
/*}*/

@media screen and (min-width: 992px) and (min-height: 800px) {
    .rdv2 .main-img__overlay--partners {
        height: 250px !important;
    }
    .rdv2 .js-dynamic-slider.init-show-slides {
        height: 120px;
    }
    .rdv2 .js-dynamic-slider .slick-slide .partner-logo {
        height: 100px;
        margin-left: 20px;
        margin-right: 20px;
    }
}









/*@media screen and (min-width: 992px) and (min-height: 700px) {*/
/*    .rdv2 .main-img__overlay--partners {*/
/*        display: block;*/
/*    }*/
/*    .rdv2 .main-img__overlay--search {*/
/*        top: 12%*/
/*    }*/
/*}*/
/*@media screen and (min-width: 992px) and (min-height: 900px) {*/
/*    .rdv2 .main-img__overlay--search {*/
/*        top: 26%;*/
/*    }*/
/*}*/
/*@media screen and (min-width: 992px) and (min-height: 1100px) {*/
/*    .rdv2 .main-img__overlay--search {*/
/*        top: 36%;*/
/*    }*/
/*}*/
/*@media screen and (min-height: 1240px) {*/
/*    .rdv2 .main-img__overlay--search {*/
/*        top: 35%;*/
/*    }*/
/*}*/
/*@media screen and (min-width: 2560px) {*/
/*    .rdv2 .main-img__overlay--search {*/
/*        top: 35%;*/
/*    }*/
/*}*/

/* legacy max width rules to override */
@media screen and (max-width: 767px) {
    header, header .navbar {
        height: 70px;
    }
    .mobile-navbar {
        top: 70px;
        font-size: 16px;
    }
    .navbar-brand>img {
        height: 45px;
        width: auto;
        max-width: none;
    }
    header .navbar {
        margin: 0;
    }
    #burger1 {
        height: unset;
        top: 10px;
    }
}



