html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    padding-top:90px;
    overflow-x: hidden;
    background: var(--color-light-grey);
}
body.naked {
    padding-top:0px !important;
}
/*@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
    }
}*/
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        padding-top:50px;
    }
    .modal-header {
        text-align: center;
    }
}

body.is-portal {
    padding-top:0;
}
.container-outer {
    position: relative;
    width: 100%;
    /*max-width:1920px;*/
    margin:0 auto;
    background:#fff;
    /*box-shadow: 0 0 25px 0 rgba(0,0,0,.1);*/
    @media screen and (max-width: 767px) {
        overflow-x: hidden;
    }
}

#mainContent {
    min-height:82vh
}

.container-fluid.container-fluid {
    max-width: 1920px;
}
.container.container {
    max-width: calc(1280rem/16);
    width: 100%;

    @media screen and (max-width: 767px) {
        padding-right: 20px;
        padding-left: 20px;
    }
}
.container.container--narrow {
    max-width: calc(1020rem/16);
}
.container.container--wide {
    max-width: calc(1530rem/16);
}
.container.container--full {
    width: 100%;
    @media screen and (max-width: 767px) {
        padding-right: 0;
        padding-left: 0;
    }
}
