/* chat */

.chat {
    position: relative;
    border-radius: 10px;
    border: 1px solid #C7C7C7;
}
.chat__body {
    padding:1.5rem;
    @media screen and (min-width: 768px) {
        padding:2.5rem;
    }
}
.chat__new-message {
    padding:.75rem 1.5rem .5rem 0;
    border-top: 1px solid #C7C7C7;
    @media screen and (min-width: 768px) {
        padding:1.25rem 2.125rem 1rem 1.125rem;
    }
}
.chat-day__headline {
    position: relative;
    display: block;
    margin:1rem auto;
    width:100%;
    max-width:500px;
    text-align: center;
}
.chat-day__headline:before {
    content:'';
    display: block;
    height:1px;
    width:auto;
    position: absolute;
    left:0;
    right: 0;
    top:50%;
    transform: translateY(-50%);
    background: #C7C7C7;
    z-index: 5;
}
.chat-day__headline>span {
    position: relative;
    display: inline-block;
    padding:0 1.25rem;
    background-color: #fff;
    z-index: 10;
}
.chat-message {
    text-align: left;
    display: inline-block;
    padding:1rem;
    border-radius:25px;
    margin-bottom:10px;
    max-width:85%;
    @media screen and (min-width: 768px) {
        padding:1rem 1.5rem;
        max-width:700px;
    }
}

.chat-message--me {
    color:#fff;
    background-color: var(--color-primary);
}
.chat-message--partner {
    background-color: #EDEDED;
}



.btn>.btn__icon.reservation-details__collapse-icon {
    font-size: .75rem;
    transform: rotate(-90deg);
    top: 0;
}
.btn.collapsed>.btn__icon.reservation-details__collapse-icon {
    transform: rotate(90deg);
    top: .125rem;
}