.teaser {
    margin:30px 0;
    position: relative;
    background:#fff;
    border-radius: 10px;
    box-shadow: 0 15px 20px 0 rgba(0,0,0,0.15);
    transition: all .1s linear;
}
.teaser:hover {
    box-shadow: 0 15px 20px 0 rgba(0,0,0,0.33);
}
.teaser__top {
    font-size: 35px;
    position: absolute;
    left:35px;
    top:-34px;
    z-index:5;
}
.teaser__img{
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.teaser__img:after{
    content:'';
    height:50px;
    display: block;
    position: absolute;
    left:0;
    right:0;
    top:0;
    background: linear-gradient(180deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0) 100%);
    z-index:2;
}
.teaser__title {
    margin-bottom: .75rem;
    line-height: 1.2;
    font-size:1.0625rem;

    @media screen and (min-width: 768px) {
        margin-bottom: 1.25rem;
        font-size:1.3125rem;
    }
}
.teaser__body {
    line-height: 1.75;
    padding:1.125rem 1.5rem 1.5rem 1.5rem;

    @media screen and (min-width: 768px) {
        padding:1.5rem 2.125rem 2.125rem 2.125rem;
    }
}