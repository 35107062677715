.number-spinner {
    position: relative;
}
.number-spinner__input {
    position: relative;
    top:-3px;
    width: 100%;
    margin:0 auto;
    background: transparent;
    text-align: center;
    padding:0;
    font-size:.875rem;
    border:0;
    -webkit-appearance: none;
}
.number-spinner__btn {
    width:1.5rem;
    height:1.5rem;
    line-height:1.75rem;
    margin:0;
    border-radius:50%;
    border:1px solid var(--color-primary);
    color: var(--color-primary);
    display: inline-block;
    font-size: 1rem;
    text-align: center;
    -webkit-appearance: none;
}

.number-spinner__btn:hover,
.number-spinner__btn:focus {
    border:1px solid var(--color-text-default);
}