.searchteaser {
    margin:1rem 0;
    position: relative;
    transition: all .2s linear;
}
.searchteaser:hover {
    transform: translateY(-.5rem);
}
.searchteaser__watchlist {
    position: absolute;
    right:0;
    top:0;
    padding:.75rem;
    color:#fff;
    font-size:1.25rem;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.33);
    z-index: 10;
    @media screen and (min-width: 768px) {
        padding:.5rem .75rem;
        font-size:1.125rem;
    }
}
.searchteaser__img:after {
    content:'';
    position: absolute;
    top:0;
    right:0;
    width:80px;
    height:80px;
    z-index:5;
    background: -moz-linear-gradient(45deg, rgba(0,0,0,0) 10%, rgba(0,0,0,0) 45%, rgba(0,0,0,.5) 100%);
    background: -webkit-linear-gradient(45deg, rgba(0,0,0,0) 10%,rgba(0,0,0,0) 45%,rgba(0,0,0,.5) 100%);
    background: linear-gradient(45deg, rgba(0,0,0,0) 10%,rgba(0,0,0,0) 45%,rgba(0,0,0,.5) 100%);
}
.searchteaser__watchlist.is-added .icon:before {
    content:var(--icon-heart-filled);
}
.searchteaser__watchlist:hover,
.searchteaser__watchlist:focus {
    color:var(--color-danger);
}
.searchteaser__img{
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.searchteaser__title {
    margin:.5rem 0;
    overflow: hidden;
    @media screen and (min-width: 768px) {
        height:4rem; /* 4rem = 3 zeilen */
    }
}
.searchteaser__title {
    margin:.5rem 0;
    line-height: 1.2;
    /*font-size:1rem;*/

    @media screen and (min-width: 768px) {
        /*font-size:1.125rem;*/
    }
}
.searchteaser__category {
    font-size: 16px;
}
.searchteaser__body {
    line-height: 1.75;
    padding:.625rem 0;
}