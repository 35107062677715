.note-editable b, .note-editable strong{
    font-family: var(--font-default);
    font-weight: var(--font-default-bold-weight); /* bold and not default, was 700 for some reason*/
}
@supports (font-variation-settings: "wdth" 315) {
    .note-editable b, .note-editable strong{
        font-family: var(--font-default-vf);
    }
}
.note-editor.note-frame {
    border:1px solid #ced4da;
    border-radius: 10px;
    overflow: hidden;
}
.note-toolbar {
}
.note-editor.note-frame .note-editing-area .note-editable {
    padding: 1.25rem;
    font-size: 15px;
}
.note-editable * {
    color: #495057 !important;
}