.alert-notification--fixed {
    z-index:999;
}
.alert-notification--image-upload .alert-heading {
    font-size: 1rem;
}

.alert a {
    text-decoration: underline;
}
.alert a:hover,
.alert a:focus {
    text-decoration: none;
}