.btn {
    display: inline-block;
    padding:.625rem 1.5rem;
    /*font-size:.75rem;*/
    /*text-transform: uppercase;*/
    letter-spacing: 1px;
    border-radius:999px;
    border:0;
    white-space: normal;
    box-shadow: 0 5px 18px 0 rgba(0,0,0,0.2);
}
.btn.disabled {
    cursor: not-allowed;
}
.btn.no-pointer {
    pointer-events: none;
}
.btn-no-shadow {
    box-shadow:none;
    border: 1px solid #c7c7c7;
}

.btn.btn-circle {
    padding:0;
    width: 42px;
    height:42px;
    font-size:24px;
    color:#fff;
    text-align: center;
    border:2px solid #fff;
    box-shadow: none;
}
.btn.btn-circle:hover,
.btn.btn-circle:focus {
    background: #fff;
    color:var(--color-primary);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.33);
}
.btn-circle>.icon {
    position: relative;
    line-height:1px;
    top:.375rem;
    left:1px;
}

.btn.btn-sm {
    padding:.375rem 1.5rem;
    font-size: .75rem;
}
.btn.btn-xs {
    padding:.375rem .75rem;
    font-size: .625rem;
}
.btn.btn-xxs {
    padding:.375rem .5rem;
    font-size: .625rem;
    line-height:1.2;
    letter-spacing: 0;
    word-break: normal;
}
@media screen and (min-width: 768px) {
    .btn {
        padding:.875rem 1.625rem;
        font-size:.9375rem;
        letter-spacing: 1.25px;
    }
    .btn.btn-lg {
        padding:1rem 3rem;
        font-size: 1.25rem;
    }
}

@media screen and (min-width: 1400px) {
    .btn {
        padding:.875rem 2.5rem;
        letter-spacing: 2px;
    }
}

.btn.icon-btn {
    padding-left:1.75rem;
    padding-right:1.75rem;
}
.btn.icon-btn .icon-btn__icon {
    position: relative;
    top:.375rem;
    line-height:1px;
    font-size:1.375rem;
    @media screen and (min-width: 768px) {
        top:.5rem;
        font-size:1.5rem;
    }
}


.btn > .btn__icon{
    position: relative;
    top:.375rem;
    line-height:1px;
    font-size:1.375rem;
    margin-right:.5rem;
    @media screen and (min-width: 768px) {
        top:.375rem;
        font-size:1.5rem;

    }
}
.btn > .btn__icon--right{
    margin-right:0;
    margin-left:.5rem;
}


/* default */
.btn.btn-default {
    width: 100%;
    background-color: #FFFFFF;
    color: #1A6402;
    text-transform: none;
    border: 1px solid #1A6402;
    @media screen and (min-width: 768px) {
        width: auto;
    }
}
.btn-default.btn-default--no-hover:hover,
.btn-default.btn-default--no-hover:focus,
.btn-default.btn-default--no-hover:active {
    color:var(--color-text-default);
    background-color: #FFFFFF;
}
.btn.btn-default:hover:not(:disabled):not(.disabled),
.btn.btn-default:focus:not(:disabled):not(.disabled),
.btn.btn-default:active:not(:disabled):not(.disabled) {
    background-color: #FFFFFF;
    color: #669C2F;
    border: 1px solid #669C2F;
}
.btn.btn-default.active--primary {
    color:var(--color-primary) !important;
    border:1px solid var(--color-primary);
    background-color: #fff;
    box-shadow: none;
    /*font-family: var(--font-default-bold);*/
    font-weight: var(--font-default-bold-weight);
}
.btn.btn-default.btn-filled {
    background: transparent linear-gradient(180deg, #78BB32 0%, #1A6402 100%) 0 0 no-repeat padding-box;
    border: none;
    color: #fff;
}

.btn.btn-default.btn-filled:hover:not(:disabled):not(.disabled),
.btn.btn-default.btn-filled:focus:not(:disabled):not(.disabled),
.btn.btn-default.btn-filled:active:not(:disabled):not(.disabled) {
    background: transparent linear-gradient(180deg, #1A6402 0%, #78BB32 100%) 0 0 no-repeat padding-box;
    border: none;
    color: #fff;
}
/*@supports (font-variation-settings: "wdth" 315) {*/
/*    .btn-default.active--primary {*/
/*        font-family: var(--font-default-vf);*/
/*    }*/
/*}*/

/* primary */
.btn.btn-primary {
    width: 100%;
    background: linear-gradient(180deg,#b845c2,#82218f);
    color:#fff;
    @media screen and (min-width: 768px) {
        width: auto;
    }
    /*background: linear-gradient(127.06deg, #B845C2 0%, #82218F 100%);*/
}

.account-box .btn.btn-default,
.account-box .btn.btn-primary {
    width: auto;
}

.btn.btn-primary:hover:not(:disabled):not(.disabled),
.btn.btn-primary:focus:not(:disabled):not(.disabled),
.btn.btn-primary:active:not(:disabled):not(.disabled) {
    background: linear-gradient(180deg,#82218f,#b845c2);
    /*background: linear-gradient(180deg, #B845C2 0%, #82218F 100%);*/
}


/* primary */
.btn.btn-muted {
    color: #C7C7C7;
    font-size:1rem;
    padding: .125rem 1rem;
    text-transform: none;
    font-weight: var(--font-default-bold-weight);
    border:1px solid #fff;
    box-shadow: 0 5px 18px 0 rgba(0,0,0,0.1);

    @media screen and (min-width: 768px) {
        font-size:1.5rem;
        padding: .125rem 1.5rem;
    }
}
/*@supports (font-variation-settings: "wdth" 315) {*/
/*    .btn.btn-muted {*/
/*        font-family: var(--font-default-vf);*/
/*    }*/
/*}*/
.btn.btn-muted:hover,
.btn.btn-muted:focus,
.btn.btn-muted:active,
.btn.btn-muted.active {
    background: #fff;
    color: var(--color-primary);
    border:1px solid var(--color-primary);
}


/* facebook */
.btn-facebook {
    color:#fff;
    background: #3b5999;
}
.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active {
    color:#fff;
    background: #32437a;
}


/* google */
.btn-google{
    color:#fff;
    background: #dc4e41;
}
.btn-google:hover,
.btn-google:focus,
.btn-google:active {
    color:#fff;
    background: #c8493c;
}



/* publish/unpublish */
.btn-default.btn--publish {
    color:#fff;
    background:var(--color-primary) !important;
    border:1px solid var(--color-primary);
}
.btn-default.btn--unpublish {
    color:#fff;
    background:var(--color-danger) !important;
    border:1px solid var(--color-danger);
}