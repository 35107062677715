.quote-container {
    position: relative;
    padding:5.625rem 0;

    @media screen and (max-width: 767px) {
        padding:3rem 0 5.5rem 0;
    }
}
.quote {
    position: relative;
    padding:2.75rem 2rem;
    border-radius: 25px;
    border: 1px solid #8F8F8F;
    background-color: #F4F4F4;
    z-index:9;
    @media screen and (min-width: 768px) {
        padding:50px 60px;
        min-height:300px;
    }
}
.quote-img {
    position: relative;
    margin:5rem -3rem 0 -3rem;
    box-shadow: 0 15px 40px 0 rgba(0,0,0,0.15);
    z-index:25;
}
.quote-person {
    margin-top:4rem;
    position: relative;
    @media screen and (max-width: 767px) {
        margin-top:63px;
    }
}
.quote-person:before{
    content:'';
    display: block;
    position:absolute;
    top:-66px;
    right:50%;
    margin-right:-27px;
    width: 53px;
    height: 42px;
    background: url(/static/img/quote.svg) 50% 50% no-repeat;
    background-size:cover;
    z-index:10;
}
@media screen and (min-width: 768px) {
    .quote-person:after{
        content:'';
        position: absolute;
        right: -162px;
        top: 12px;
        width: 162px;
        height: 3px;
        z-index: 33;
        background-color: var(--color-primary);
    }
}
.quotes-top{
    color:#78AA2D;
    position:absolute;
    top:-2.75rem;
    left:3rem;
    width: 100px;
    height: 70px;
    font-size:4.5rem;
    line-height:1px;
    z-index:10;
    text-align:center;
    @media screen and (max-width: 767px) {
        font-size:2.5rem;
    }
}

.quotes-top .icon{
    @media screen and (max-width: 767px) {
        position:relative;
        top:1.375rem;
    }
}

.quotes-top:before{
    content:'';
    position:absolute;
    top:43px;
    left:0;
    right: -10px;
    width: auto;
    height: 1px;
    background: #F4F4F4;
}
.quote p:last-child {
    margin: 0;
}


/* slider adaptions */
.quote-arrow-holder {
    position: absolute;
    width: 100px;
    height:55px;
    bottom:5.875rem;
    left:4rem;

    @media screen and (max-width: 767px) {
        bottom:1rem;
        left:auto;
        right:1.125rem;
    }
}
.quote-arrow-holder .slick-arrow {
    margin-top:-1.5rem;
    font-size:3rem;
    width:3rem;
    height:3rem;
}