/* overview */
@media screen and (min-width: 768px) {
    .account-teaser-img-col {
        flex: 0 0 240px;
    }
}

.account-teaser {
    position: relative;
    border-radius: 10px;
    margin:1.75rem 0;
    border: 1px solid #C7C7C7;
}
.account-teaser--highlight {
    border:0;
    box-shadow: 0 15px 40px 0 rgba(0,0,0,0.15);
}
.account-teaser--chat-head {
    margin:0;
    border:0;
    border-bottom:1px solid #C7C7C7;
    border-radius:0;
}
.account-teaser--chat-head .account-teaser__img {
    @media screen and (min-width: 768px) {
        border-bottom-left-radius: 0;
    }
}
.account-teaser__img {
    overflow: hidden;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;

    @media screen and (min-width: 768px) {
        border-top-right-radius: 0;
        border-bottom-left-radius: 9px;
    }
}
.account-teaser__img--with-overlay:after {
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    content:'';
    background: rgba(0,0,0,.35);
    z-index:4;
}
.account-teaser__overlay {
    position: absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);
    right:0;
    color:#fff;
    text-align: center;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    z-index:8;
}
@supports (font-variation-settings: "wdth" 315) {
    .account-teaser__overlay {
        font-family: var(--font-default-vf);
    }
}

.account-teaser__overlay > .icon {
    font-size:1.75rem;
    line-height:1;
}
.account-teaser__body {
    padding:1rem;
    @media screen and (min-width: 768px) {
        padding:.75rem 2rem .75rem 0;
    }
}
.account-teaser__msg-hint {
    display: block;
    position: absolute;
    right:-10px;
    top:-10px;
    font-size:1.75rem;
    z-index:11;
}