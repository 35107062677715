.content-block + .content-block,
.pimcore_area_content + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: 2rem;

    @media screen and (min-width: 768px) {
        margin-top: 5rem;
    }
}


.content-block + .content-block.content-block--sm,
.pimcore_area_content + .content-block.content-block--sm,
.content-block + .pimcore_area_content > .content-block.content-block--sm,
.pimcore_area_content + .pimcore_area_content > .content-block.content-block--sm {
    margin-top: 1.5rem;
    @media screen and (min-width: 768px) {
        margin-top: 3.5rem;
    }
}


.content-block.content-block--with-background {
    padding:2rem 0;
    @media screen and (min-width: 768px) {
        padding:5.5rem 0;
    }
}


.content-block.content-block--forms {
    padding:2rem 0;
    @media screen and (min-width: 768px) {
        padding:5.5rem 0;
    }
}



