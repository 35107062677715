/*Margin Helper*/


/*Padding Helper*/


/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.hasDatepicker,
.isClickable {
    cursor: pointer;
}
.js-non-crawlable,
.js-non-crawlable-link {
    cursor: pointer;
}
.cursor-not-allowed.cursor-not-allowed {
    cursor: not-allowed;
}

/* font sizes */
.fs1 {
    font-size: 1rem !important;
}
.fs0-875 {
    font-size: .875rem !important;
}
.fs1-125 {
    font-size: 1.125rem !important;
}
.fs1-25 {
    font-size: 1.25rem !important;
}
.fs1-5 {
    font-size: 1.5rem !important;
}
.fs2 {
    font-size: 2rem !important;
}
.fs2-5 {
    font-size: 2.5rem !important;
}

/*Text Color Helper*/
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default) !important; }
.text-primary { color: var(--color-primary); }
.text-secondary { color: var(--color-secondary); }

.text-published {
    color:var(--color-primary);
}
.text-unpublished {
    color:var(--color-danger);
}

/*Background Color Helper*/
.bg-primary { background: var(--color-primary); }
.bg-secondary { background: var(--color-secondary); }
.bg-light-grey { background: var(--color-light-grey); }
.bg-pattern {
    background: url(/static/img/pattern.png) 50% 50% repeat;
}
.bg-gradient {
    background: linear-gradient(127.06deg, #B845C2 0%, #82218F 100%);
}
@media screen and (min-width: 768px) {
    .col-with-loading-bg {
        background-image:url(/static/img/loading.gif);
        background-position: 50% 40%;
        background-repeat: no-repeat;
    }
}
.dashed {
    border:1px dashed #c7c7c7 !important;
}
a.collapsed .fake-swipe,
.fake-swipe {
    width: 60px;
    height:40px;
    display: inline-block;
    box-shadow: 0 5px 18px 0 rgba(0,0,0,0.2);
    border-radius: 25px;
    background-color: #C7C7C7;
    position: relative;
}
a:not(.collapsed) .fake-swipe,
.fake-swipe--active {
    background-color: var(--color-primary);
}
a.collapsed .fake-swipe:before,
.fake-swipe:before {
    content:'';
    position: absolute;
    left:0;
    top:0;
    height: 40px;
    width: 40px;
    border: 1px solid #C7C7C7;
    background-color: #FFFFFF;
    border-radius: 50%;
    transition: all .2s linear;
}
a:not(.collapsed) .fake-swipe:before,
.fake-swipe--active:before {
    left:21px;
}

.h-100 {
    height:100%;
}

.is-blurry {
    color: transparent;
    text-shadow: 0 0 8px rgba(0,0,0,0.5);
}

.remove-link {
    position: absolute;
    left:-.375rem;
    top:-.375rem;
    width:1.25rem;
    height:1.25rem;
    line-height: 1.25rem;
    display: inline-block;
    text-align: center;
    color:#000;
    font-size:.875rem;
    border-radius: 50%;
    background:var(--color-light-grey);
    z-index:5;
}
.input-lang-flag {
    position: absolute;
    left:-.375rem;
    bottom:-.25rem;
    width:1.25rem;
    height:1.25rem;
    line-height: 1.25rem;
    display: inline-block;
    text-align: center;
    color:#000;
    font-size:.875rem;
    border-radius: 50%;
    background:url(/static/img/flag-austria.svg) 50% 50% no-repeat;
    background-size: cover;
    z-index:5;
}
.input-lang-flag--en {
    background:url(/static/img/flag-uk.svg) 50% 50% no-repeat;
    background-size: cover;
}


.shadow-img {
    box-shadow: 0 8px 10px 0 rgba(0,0,0,0.25);
    @media screen and (min-width: 768px) {
        box-shadow: 0 15px 30px 0 rgba(0,0,0,0.25);
    }
}

.pdf-icon {
    border: 0 !important;
    width:1.5rem;
    height:1.5rem;
    top: -.375rem;
    left:-.5rem;
    background-size: contain;
    background: url(/static/img/pdf.svg) 50% 50% no-repeat;
}

.col--with-border-left {
    border-left:1px solid #979797;
}
.col--with-border-right {
    border-right:1px solid #979797;
}

@media screen and (min-width: 768px) {
    .col-middle-border:nth-child(2) {
        border-right:1px solid var(--color-light-grey);
        border-left:1px solid var(--color-light-grey);
    }

}

.tooltip {pointer-events: none; font-weight: var(--font-default-weight)}

.textline-with-icon {
    position: relative;
    padding-left:2.25rem;
}
.textline-with-icon a {
    color:var(--color-primary);
    text-decoration: underline;
}
.textline-with-icon a:hover,
.textline-with-icon a:focus {
    text-decoration: none;
}
.textline-with-icon>.pdf-icon,
.textline-with-icon>.textline-with-icon__status,
.textline-with-icon>.textline-with-icon__icon {
    position: absolute;
    line-height:1px;
    font-size: 1.25rem;
    top: 15px;
    left:0;
}
.textline-with-icon>.pdf-icon {
    top: 2px;
}
.textline-with-icon .textline-with-icon__collapse-icon {
    position: absolute;
    line-height:1px;
    font-size: .75rem;
    top: .875rem;
    right:-1.5rem;
    transform: rotate(-90deg);
}
.textline-with-icon.collapsed .textline-with-icon__collapse-icon {
    transform: rotate(90deg);
}

.textline-with-icon>.textline-with-icon__status {
    top: 2px;
    display: block;
    width:1.5rem;
    height:1.5rem;
    border:.125rem solid #fff;
    border-radius:50%;
    background-color: #c7c7c7;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.4);
}
.textline-with-icon>.textline-with-icon__status--waiting {
    background-color: var(--color-res-state-awaiting-landlord);
}
.textline-with-icon>.textline-with-icon__status--payed,
.textline-with-icon>.textline-with-icon__status--payment {
    background-color: var(--color-res-state-awaiting-renter);
}
.textline-with-icon>.textline-with-icon__status--success {
    background-color: var(--color-res-state-completed);
}
.textline-with-icon>.textline-with-icon__status--cancel {
    background-color: var(--color-res-state-canceled);
}
.textline-with-icon>.textline-with-icon__status--timedout {
    background-color: var(--color-res-state-timedout);
}

a.link-with-icon {
    position: relative;
    padding-left:1.5rem;
}

a.link-with-icon.link-with-icon:hover,
a.link-with-icon.link-with-icon:focus {
    border:0;
}

a.link-with-icon>.bg-icon,
a.link-with-icon>.icon {
    position: absolute;
    line-height:1px;
    font-size: .875rem;
    top: .375rem;
    left:0;
}
a.link-with-icon:hover>span,
a.link-with-icon:focus>span {
    border-bottom:1px solid;
}
.padding-top-20 {
    padding-top: 20px;
}