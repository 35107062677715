.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}
.custom-checkbox:after {
    clear: both;
}
.custom-checkbox {
    display: inline-block;
    position: relative;
}
.custom-checkbox--inline {
    margin-right:2rem;
}
.custom-checkbox--room-selection {
    @media screen and (max-width: 767px) {
        display:block;
        white-space:nowrap;
        margin-bottom:.5rem;
    }
}
.custom-checkbox--room-selection label {
    @media screen and (max-width: 767px) {
        display:block;
    }
}
.custom-checkbox--small {
    font-size:.875rem;
    line-height: 1.125;
}
.custom-checkbox label {
    cursor: pointer;
}

.custom-checkbox label a {
    color:var(--color-primary);
    text-decoration: underline;
}
.custom-checkbox label a:hover,
.custom-checkbox label a:focus {
    color: var(--color-text-default);
    text-decoration: underline;
}
/*.custom-checkbox label.has-error {
    color: var(--color-danger)
}*/
.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    position: relative;
    float: left;
    margin-right: calc(7rem/16);
    overflow: hidden;
    width: 1.25rem;
    font-size: 1rem;
    /*top:1px;*/
    top:7px;
    text-align:left;

    /*@media screen and (min-width: 768px) {*/
    /*    top:7px;*/
    /*}*/
}

.custom-checkbox--small .custom-checkbox__box {
    top:-2px;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box {
    color: var(--color-primary);
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    content:var(--icon-checkbox-filled);
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:after {
    content: "";
    display: block;
    position: absolute;
    left: 5px;
    top: 2px;
    width: 6px;
    height: 9px;
    border: 2px solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
    cursor: pointer;
}

