.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}
.custom-radio:after {
    clear: both;
}
.custom-radio {
    display: inline-block;
    position: relative;
}
.custom-radio--with-border {
    display: block;
    padding:.75rem 0 .375rem 0;
    border-top:1px solid var(--color-light-grey);
}
.custom-radio--inline {
    margin-right:2rem;
    display: inline-block;
}
.custom-radio label {
    cursor: pointer;
    display: block;
}
/*.custom-radio label.has-error {
    color: var(--color-danger)
}*/
.custom-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: relative;
    float: left;
    margin-right: calc(10rem/16);
    overflow: hidden;
    border: 1px solid var(--color-default);
    background: #ffffff;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    top: 7px;
    text-align:left;

    /*@media screen and (min-width: 768px) {*/
    /*    top: 7px;*/
    /*}*/
}
.custom-radio__box:before {
    content: "";
    display: block;
    position: absolute;
    left: 5px;
    top: 2px;
    width: 6px;
    height: 9px;
    border: 2px solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:checked ~ .custom-radio__box {
    border-color: var(--color-primary);
    background: var(--color-primary);
}
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-primary);
}

.has-error .custom-radio__box {
    border-color: var(--color-danger)
}
.custom-radio__text {
    display: block;
    overflow: hidden;
}
