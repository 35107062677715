.embed-responsive-gallery-admin-headerimg:before {
    padding-top: 75%;
    @media screen and (min-width: 768px) {
        padding-top: 25.5%;
    }
}

.gallery-admin-upload-container,
.gallery-admin-img-container {
    position: relative;
    @media screen and (min-width: 768px) {
        margin:0 -2rem;
    }
}
.gallery-admin-position {
    display: block;
    padding:.75rem 1rem;
    position: absolute;
    background: transparent;
    left:.75rem;
    top:50%;
    transform: translateY(-50%);
    z-index:10;
    border:0;
    -webkit-appearance: none;

    @media screen and (min-width: 768px) {
        padding:.375rem .75rem;
    }
}

.gallery-admin-position .icon {
    pointer-events: none;
}
.gallery-admin-position--top {
    margin-top:-1.375rem;
    border-bottom:1px solid #000;
}
.gallery-admin-position--bottom {
    margin-top:1.375rem;
    border-top:1px solid #000;
}

.gallery-admin-row {
    position: relative;
    padding:1rem 0;
    background:#fff;

    @media screen and (min-width: 768px) {
        padding:1.5rem 2rem 1.5rem 3.5rem;
    }
}

.gallery-admin-row--upload{
    padding:0;
    @media screen and (min-width: 768px) {
        padding:0 0 0 1.5rem;
    }
}


@media screen and (min-width: 768px) {
    .gallery-admin-row:not(.gallery-admin-row--upload):focus,
    .gallery-admin-row:not(.gallery-admin-row--upload):hover {
        box-shadow: 0 5px 40px 0 rgba(0,0,0,0.1);
    }
}