html {
/* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}


body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
    font-size: var(--font-size-default);
    line-height:1.75;

    /*@media screen and (max-width: 767px) {*/
    /*    font-size:15px;*/
    /*    line-height:1.5;*/
    /*}*/
}
@supports (font-variation-settings: "wdth" 315) {
    body {
        font-family: var(--font-default-vf);
    }
}

/*#mainContent a:not(.btn) {
    color:var(--color-primary);
    text-decoration: underline;
}
#mainContent a:not(.btn):hover,
#mainContent a:not(.btn):focus {
    color: var(--color-text-default);
    text-decoration: underline;
}*/

b,
strong,
.font-bold,
.strong{
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}
@supports (font-variation-settings: "wdth" 315) {
    b,
    strong,
    .font-bold,
    .strong{
        font-family: var(--font-default-vf);
    }
}


@media screen and (max-width: 767px) {
    .xs-font-bold,
    .xs-strong {
        font-family: var(--font-default-bold);
        font-weight: var(--font-default-bold-weight);
    }
}
@supports (font-variation-settings: "wdth" 315) {
    .xs-font-bold,
    .xs-strong {
        font-family: var(--font-default-vf);
    }
}

.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
@supports (font-variation-settings: "wdth" 315) {
    .font-default {
        font-family: var(--font-default-vf);
    }
}
.font-special,
.font-decorative{
    font-family: var(--font-decorative);
    font-size: 1.2rem;
    font-weight: var(--font-decorative-weight);
    text-transform: uppercase;
    letter-spacing: 2.4px;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
    display: block;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}
@supports (font-variation-settings: "wdth" 315) {
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        font-family: var(--font-default-vf);
    }
}

h1, .h1 {
    font-size: calc(24rem/16);
}
h2, .h2 {
    font-size: calc(15rem/16);
    font-family: var(--font-decorative);
    font-weight: var(--font-decorative-weight);
    text-transform: uppercase;
    letter-spacing: 2.4px;
}
h3, .h3 {
    font-size: calc(18rem/16);
}
h4, .h4 {
    /*font-size: calc(15rem/16);*/
    font-size: 18px;
}
h5, .h5 {
    font-size: 1rem;
}
h6, .h6 {
    font-size: 1rem;
}


@media screen and (min-width: 768px) {
    h1, .h1 {
        font-size: calc(24rem/16);
    }
    h2, .h2 {
        font-size: calc(15rem/16);
    }
    h3, .h3 {
        font-size: calc(25rem/16);
    }
    h4, .h4 {
        font-size: calc(22rem/16);
    }
    h5, .h5 {
        font-size: calc(18rem/16);
    }
}

@media screen and (min-width: 1200px) {
    h1, .h1 {
        font-size: calc(34rem/16);
    }
    h2, .h2 {
        font-size: calc(22rem/16);
    }
}