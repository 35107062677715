/* main */
.gallery-slider {
    position:relative;
    @media screen and (max-width: 767px) {
        margin-bottom:6rem;
    }
}

/* arrows */
.gallery-slider .slick-arrow {
    right:auto;
    left:4rem;
    top: auto;
    bottom:-105px;
    margin:0;

    @media screen and (max-width: 767px) {
        bottom:-4rem;
        left:auto;
        right:.25rem;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        left:auto;
        bottom:-4.5rem;
        right:.25rem;
    }
}

.gallery-slider .slick-prev {
    left:0;

    @media screen and (max-width: 767px) {
        left:auto;
        right:3.25rem;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        right:4.5rem;
    }
}




/* thumbs */
.gallery-slider__thumbs {
    padding:10px;
    right:-20px;
    top:-10px;
}
.gallery-slider__thumb {
    margin:20px 10px;
}
.gallery-slider__thumb__img {
    opacity: .4;
    cursor: pointer;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.3);
    transition: all .2s linear;
}
.gallery-slider__thumb:hover .gallery-slider__thumb__img,
.gallery-slider__thumb:focus .gallery-slider__thumb__img,
.slick-slide.slick-current .gallery-slider__thumb__img {
    opacity: 1;
}
.slick-slide.slick-current .gallery-slider__thumb__img:after {
    content:'';
    z-index: 5;
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    border:5px solid #fff;
}