.breadcrumb {
    font-size: 1rem;
    background: none;
    padding:0;
    margin: 0;
    color:var(--color-text-muted);
}

.breadcrumb-item {
    position: relative;
    color: var(--color-text-muted);
}
.breadcrumb-item .icon {
    line-height: 1.5;
}

.breadcrumb-item.active {
    color: var(--color-text-default);
}

.breadcrumb-item:not(.active):hover {
    color: #000;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left:2.5rem;
}
.breadcrumb-item+.breadcrumb-item:before {
    padding:0;
    position: absolute;
    left:1.125rem;
    bottom:.375rem;
    content:var(--icon-chevron);
    font-family: iconfont;
    font-size:.5rem;
    color: var(--color-text-default);
}

@media screen and (max-width: 767px) {
    .breadcrumb-container {
        display: block;
        position: relative;
        width: 100%;
    }
    .breadcrumb-container:after {
        position: absolute;
        display: block;
        content: "";
        right: 0;
        top: 0;
        bottom: 0;
        width: 60px;
        height: auto;
        z-index: 4;
        background: linear-gradient(90deg,hsla(0,0%,100%,0) 0,#fff);
    }
    .breadcrumb {
        font-size: .875rem;
        display: block;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
    }
    .breadcrumb-item {
        display: inline-block;
    }
    .breadcrumb-item:last-of-type {
        padding-right: 2rem;
    }
}
