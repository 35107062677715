@media screen and (min-width: 768px) {
    /* main */
    header .navbar-nav .nav-link.nav-link {
        padding: 0.5rem 0.75rem;
        font-size: .9rem;
        position: relative;
        white-space: nowrap;
    }

    header .navbar-nav .nav-link:before {
        content: '';
        display: block;
        width: 40px;
        height: 1px;
        position: absolute;
        left: 50%;
        bottom: 2px;
        margin-left: -20px;
        background: transparent;
        transform: translateX(-40px);
        transition: all .2s linear;
    }

    header.header-light .navbar-nav .nav-link.nav-link {
        color:#fff;
    }

    header .navbar-nav .nav-link:hover:before,
    header .navbar-nav .nav-link:focus:before,
    header .navbar-nav .nav-link:active:before,
    header .navbar-nav .nav-link.active:before,
    header .navbar-nav .active > .nav-link:before {
        transform: translateX(0);
        background: #000;
    }
    header.dashboard .navbar-nav .nav-link:hover:before,
    header.dashboard .navbar-nav .nav-link:focus:before,
    header.dashboard .navbar-nav .nav-link:active:before,
    header.dashboard .navbar-nav .nav-link.active:before,
    header.dashboard .navbar-nav .active > .nav-link:before {
        background: #fff;
    }
    header.dashboard .navbar-nav .nav-item.active {
        font-weight: bold;
    }
    header.header-light .navbar-nav .nav-link:hover:before,
    header.header-light .navbar-nav .nav-link:focus:before,
    header.header-light .navbar-nav .nav-link:active:before,
    header.header-light .navbar-nav .nav-link.active:before,
    header.header-light .navbar-nav .active > .nav-link:before {
        background: #fff;
    }
    #navbarMain .icon {
        display: none;
    }
    .icon-white {
        display: none;
    }
    .dashboard .icon-white,
    .header-light .icon-white{
        display: block;
    }
    .dashboard .icon-black,
    .header-light .icon-black {
        display: none;
    }

    .dashboard .back {
        background: #fff;
        width: 3rem;
        position: absolute;
        right: 0;
        height: 100%;
        top: 0;
        color: black;
    }
    .dashboard .backbutton {
        position: absolute;
        left: 0.5rem;
        top: 25px;
    }
    .dashboard .backbutton img {
        width: 30px;
    }
    .dropdown.desktop-profile a.active {
        font-weight: bold;
    }

    /* right */
    .navbar-right__list-item.navbar-right__list-item {
        margin:0;
    }
    header .navbar-right__item {
        position: relative;
        display: inline-block;
        margin:0 .375rem;
        padding:.5rem .25rem .5rem 1.5rem;
        font-size:0.9rem;
    }
    header.header-light .navbar-right__item {
        color:#fff;
    }
    header .navbar-right__item>.icon {
        position: absolute;
        left:0;
        top: .75rem;
        font-size: 1.125rem;
    }

    #dropdownLanguageLink img {
        width: 1.125rem;
    }


    header .navbar-right__item--right-icon {
        padding:.5rem 1.25rem .5rem 0;
    }
    header .navbar-right__item--left-icon {
        padding:.5rem 1.25rem .5rem 1.5rem;
    }
    header .navbar-right__item>.icon-chevron {
        font-size: .75rem !important;
        left:auto;
        right:0;
        top: .875rem !important;
        transform: rotate(90deg);
    }

}

/*@media screen and (min-width: 1400px) {*/
/*    header .navbar-nav .nav-link.nav-link {*/
/*        padding: .5rem 1.75rem;*/
/*        font-size: 1.125rem;*/
/*    }*/
/*    header .navbar-right__item {*/
/*        font-size:1.125rem;*/
/*        margin:0 .5rem;*/
/*        padding:.5rem .75rem .5rem 2rem;*/
/*    }*/
/*    header .navbar-right__item--right-icon {*/
/*        padding:.5rem 1.5rem .5rem .125rem;*/
/*    }*/
/*    header .navbar-right__item--left-icon {*/
/*        padding-left: 2rem;*/
/*    }*/
/*    header .navbar-right__item>.icon {*/
/*        top: .625rem;*/
/*        font-size: 1.375rem;*/
/*    }*/
/*    #dropdownLanguageLink img {*/
/*        width: 1.375rem;*/
/*    }*/
/*    header.dashboard .navbar-right {*/
/*        padding-right: 3rem;*/
/*    }*/
/*}*/

@media screen and (min-width: 1100px) {
    header .navbar-nav .nav-link.nav-link {
        padding: .5rem 1rem;
        font-size: 1rem;
    }
    header .navbar-right__item {
        padding: .5rem 1.25rem .5rem 1.5rem;
        font-size:1rem;
    }
}
@media screen and (min-width: 1600px) {
    .navbar-brand-container .navbar-brand {
        min-width: 200px;
    }

    header .navbar-nav .nav-link.nav-link {
        padding: .5rem 1.75rem;
        font-size: 1.125rem;
    }
    header .navbar-right__item {
        font-size:1.125rem;
        margin:0 .5rem;
        padding:.5rem .75rem .5rem 2rem;
    }
    header .navbar-right__item--right-icon {
        padding:.5rem 1.5rem .5rem .125rem;
    }
    header .navbar-right__item--left-icon {
        padding-left: 2rem;
    }
    header .navbar-right__item>.icon {
        top: .625rem;
        font-size: 1.375rem;
    }
    #dropdownLanguageLink img {
        width: 1.375rem;
    }
    header.dashboard .navbar-right {
        padding-right: 3rem;
    }
}

/* dropdown */

@media screen and (min-width: 768px) {
    .nav-item {
        position: relative;
    }
    .nav-item:hover .dropdown-menu {
        display: block;
    }
    #navbarMain .dropdown-menu {
        margin-top: 0;
    }
    .dropdown-menu {
        border:0;
    }
}



