header {
    position: fixed;
    left:0;
    right:0;
    top:0;
    height: 90px;
    background:#fff;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
    z-index:1010;
}
header.dashboard {
    background: var(--color-primary) !important;
    color: white;
}
header .navbar {
    /*margin:.875rem 0;*/
    /*padding:0 1rem;*/
    padding:0 8px 0 0;
    height: 90px;
}
.navbar-brand {
    display: inline-block;
    padding:0;
    margin:0;
    /*width: 100%;*/
}
.navbar-brand>img {
    display: inline-block;
    width: 100%;
    max-width: 180px;
}

/*.navbar-right-container > .navbar-right {*/
/*    width: 400px;*/
/*}*/

.mobile-nav-list .nav-item.has-subnav .nav-link {
    display: inline-block;
}
.mobile-nav-list .nav-item .icon-chevron {
    font-size: .75rem;
    transform: rotate(90deg);
    margin-left: 10px;
}

@media screen and (max-width: 767px) {
    header {
        height: 50px;
    }
    header .navbar {
        height: 50px;
    }
    body.nav-open header {
        background: #fff;
    }
    header .navbar {
        margin:.2rem 0;
    }
    .navbar-brand>img {
        max-width: 110px;
    }
    .navbar-brand-container {
        width: 100%;
    }
    .navbar-toggler {
        /*padding: .25rem 0 .25rem .75rem;*/
        font-size: 1.5rem;
    }
    .header-light .navbar-toggler {
        color:#fff!important;
    }
    .header-light .navbar-toggler.active {
        color:#000!important;
    }
    .header-light .navbar-toggler #burger1 span {
        background-color: var(--color-secondary)  !important;
        /*background-color:#fff!important;*/
    }
    .header-light .navbar-toggler.active #burger1 span{
        background-color: var(--color-secondary)  !important;
        /*background-color:#000!important;*/
    }
    .dashboard .navbar-toggler #burger1 span {
        background-color: var(--color-secondary)  !important;
        /*background-color:#fff!important;*/
    }
    .dashboard .navbar-toggler.active #burger1 span {
        background-color: var(--color-secondary)  !important;
        /*background-color:#fff!important;*/
    }
    .navbar-toggler #burger1 span{
        background-color: var(--color-secondary)  !important;
        /*background-color:#000!important;*/
    }
    .navbar-toggler.active #burger1 span{
        background-color: var(--color-secondary)  !important;
        /*background-color:#000!important;*/
    }
    .navbar-toggler.active {
        color:var(--color-text-default);
    }
    .navbar-toggler.active > .icon:before {
        content:var(--icon-close);
    }
}







/* anpassung portal */
header.header-light {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#070707+0,070707+100&0.52+0,0+100 */
    background: -moz-linear-gradient(top, rgba(7,7,7,0.52) 0%, rgba(7,7,7,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(7,7,7,0.52) 0%,rgba(7,7,7,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(7,7,7,0.52) 0%,rgba(7,7,7,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#85070707', endColorstr='#00070707',GradientType=0 ); /* IE6-9 */
    box-shadow:none;
}