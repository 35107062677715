.marker-cluster__count {
    position: absolute;
    left: 50%;
    top: 50%;
    color:#fff;
    font-size:1.125rem;
    transform: translateY(-50%) translateX(-50%);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}
@supports (font-variation-settings: "wdth" 315) {
    .marker-cluster__count {
        font-family: var(--font-default-vf);
    }
}
.leaflet-popup-content.leaflet-popup-content {
    margin: 12px;
}