.loading-spinner {
    margin-left: auto;
    margin-right: auto;
    width: 70px;
    text-align: center;
    position: relative;
    padding: 1.25rem;
    height: 5rem;
}
.loading-spinner-center {
    position: fixed;
    left:0;
    right:0;
    top:50%;
    transform: translateY(-50%);
}
.loading-spinner__item {
    color: var(--color-primary);
    position: absolute;
    top: 0;
    left: calc(50% - 2rem);
}
.loading-spinner__item--1,
.loading-spinner__item--2 {
    font-size: calc(64rem/16);
    font-family: iconfont;
}
.loading-spinner__item--1:before {
    content: var(--icon-clock-only);
}

.loading-spinner__item--2:before {
    content: var(--icon-clock-hands);
}
.loading-spinner__item--2 {
    animation: loading-spinner-rotate 2s infinite ease-in-out;
    transform-origin: 50%;
}

.loading-spinner i {
    position: absolute;
    font-size: 70px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: var(--color-secondary);
}
.loading-spinner i.icon-animated-signet_clock_hands {
    animation: loading-spinner-rotate 2s infinite ease-in-out;
    left: 2px;
    top: 0px;
    height: 68px;
    width: 68px;
}

@keyframes loading-spinner-rotate {
    0% {
        transform: rotate(0deg);
    }
    12.5% {
        transform: rotate(45deg);
    }
    25% {
        transform: rotate(90deg);
    }
    37.5%{
        transform: rotate(135deg);
    }
    50%{
        transform: rotate(180deg);
    }
    62.5%{
        transform: rotate(225deg);
    }
    75%{
        transform: rotate(270deg);
    }
    87.5%{
        transform: rotate(315deg);
    }
    100%{
        transform: rotate(360deg);
    }
}