@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1741169157715/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1741169157715/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1741169157715/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-aktiviert:before { content: "\EA01" }
.icon-animated-signet_circle:before { content: "\EA02" }
.icon-animated-signet_clock_hands:before { content: "\EA03" }
.icon-animated-signet_outside:before { content: "\EA04" }
.icon-arrow-long:before { content: "\EA05" }
.icon-bus:before { content: "\EA06" }
.icon-button-arrow:before { content: "\EA07" }
.icon-calendar-filled:before { content: "\EA08" }
.icon-calendar:before { content: "\EA09" }
.icon-checkbox-empty:before { content: "\EA0A" }
.icon-checkbox-filled:before { content: "\EA0B" }
.icon-checkin:before { content: "\EA0C" }
.icon-checkout:before { content: "\EA0D" }
.icon-chevron:before { content: "\EA0E" }
.icon-clock-hands:before { content: "\EA0F" }
.icon-clock-only-v2:before { content: "\EA10" }
.icon-clock-only:before { content: "\EA11" }
.icon-close:before { content: "\EA12" }
.icon-deaktiviert:before { content: "\EA13" }
.icon-delete:before { content: "\EA14" }
.icon-doppelbett:before { content: "\EA15" }
.icon-edit:before { content: "\EA16" }
.icon-einzelbett:before { content: "\EA17" }
.icon-etagenbett:before { content: "\EA18" }
.icon-expose:before { content: "\EA19" }
.icon-facebook:before { content: "\EA1A" }
.icon-gplus:before { content: "\EA1B" }
.icon-grundrissplan:before { content: "\EA1C" }
.icon-handtuch:before { content: "\EA1D" }
.icon-haus:before { content: "\EA1E" }
.icon-heart-filled:before { content: "\EA1F" }
.icon-heart:before { content: "\EA20" }
.icon-help:before { content: "\EA21" }
.icon-icon-back-w:before { content: "\EA22" }
.icon-icon-back:before { content: "\EA23" }
.icon-icon-language-w:before { content: "\EA24" }
.icon-icon-language:before { content: "\EA25" }
.icon-icon-reservierung-outline:before { content: "\EA26" }
.icon-icon-reservierungsnummer-outline:before { content: "\EA27" }
.icon-icon-reservierungsnummer:before { content: "\EA28" }
.icon-icon_attention:before { content: "\EA29" }
.icon-icon_confirm:before { content: "\EA2A" }
.icon-icon_expand:before { content: "\EA2B" }
.icon-icon_facebook:before { content: "\EA2C" }
.icon-icon_filter:before { content: "\EA2D" }
.icon-icon_gallery:before { content: "\EA2E" }
.icon-icon_grundausstattung:before { content: "\EA2F" }
.icon-icon_iCal:before { content: "\EA30" }
.icon-icon_info:before { content: "\EA31" }
.icon-icon_instagram:before { content: "\EA32" }
.icon-icon_kostenkontrolle:before { content: "\EA33" }
.icon-icon_kueche:before { content: "\EA34" }
.icon-icon_linkedin:before { content: "\EA35" }
.icon-icon_liste:before { content: "\EA36" }
.icon-icon_merkliste:before { content: "\EA37" }
.icon-icon_minimize:before { content: "\EA38" }
.icon-icon_play:before { content: "\EA39" }
.icon-icon_share:before { content: "\EA3A" }
.icon-icon_sortieren:before { content: "\EA3B" }
.icon-icon_stadt:before { content: "\EA3C" }
.icon-info:before { content: "\EA3D" }
.icon-instagram:before { content: "\EA3E" }
.icon-kamin:before { content: "\EA3F" }
.icon-kinderbett:before { content: "\EA40" }
.icon-kitchen:before { content: "\EA41" }
.icon-klopapier:before { content: "\EA42" }
.icon-kzm-clock:before { content: "\EA43" }
.icon-kzm-clockv2:before { content: "\EA44" }
.icon-lift:before { content: "\EA45" }
.icon-mail:before { content: "\EA46" }
.icon-map-pin:before { content: "\EA47" }
.icon-menu:before { content: "\EA48" }
.icon-message-filled:before { content: "\EA49" }
.icon-message:before { content: "\EA4A" }
.icon-mietvertrag:before { content: "\EA4B" }
.icon-minus:before { content: "\EA4C" }
.icon-mouse:before { content: "\EA4D" }
.icon-objekte-filled:before { content: "\EA4E" }
.icon-objekte:before { content: "\EA4F" }
.icon-objektnummer:before { content: "\EA50" }
.icon-open:before { content: "\EA51" }
.icon-password:before { content: "\EA52" }
.icon-personenanzahl:before { content: "\EA53" }
.icon-phone:before { content: "\EA54" }
.icon-plane:before { content: "\EA55" }
.icon-play:before { content: "\EA56" }
.icon-poi-outline:before { content: "\EA57" }
.icon-poi:before { content: "\EA58" }
.icon-pool:before { content: "\EA59" }
.icon-quotes:before { content: "\EA5A" }
.icon-reservierung:before { content: "\EA5B" }
.icon-sauna:before { content: "\EA5C" }
.icon-search:before { content: "\EA5D" }
.icon-senden:before { content: "\EA5E" }
.icon-sofabett-1:before { content: "\EA5F" }
.icon-sofabett-2:before { content: "\EA60" }
.icon-spinner-minus:before { content: "\EA61" }
.icon-spinner-plus:before { content: "\EA62" }
.icon-supermarket:before { content: "\EA63" }
.icon-to-do-list:before { content: "\EA64" }
.icon-train:before { content: "\EA65" }
.icon-uhr:before { content: "\EA66" }
.icon-user-filled:before { content: "\EA67" }
.icon-user:before { content: "\EA68" }
.icon-usp_clock:before { content: "\EA69" }
.icon-usp_employee:before { content: "\EA6A" }
.icon-usp_house:before { content: "\EA6B" }
.icon-wohnflaeche:before { content: "\EA6C" }
.icon-zentrum:before { content: "\EA6D" }
.icon-zustellbett:before { content: "\EA6E" }


:root {
--icon-aktiviert: "\EA01";
    --icon-animated-signet_circle: "\EA02";
    --icon-animated-signet_clock_hands: "\EA03";
    --icon-animated-signet_outside: "\EA04";
    --icon-arrow-long: "\EA05";
    --icon-bus: "\EA06";
    --icon-button-arrow: "\EA07";
    --icon-calendar-filled: "\EA08";
    --icon-calendar: "\EA09";
    --icon-checkbox-empty: "\EA0A";
    --icon-checkbox-filled: "\EA0B";
    --icon-checkin: "\EA0C";
    --icon-checkout: "\EA0D";
    --icon-chevron: "\EA0E";
    --icon-clock-hands: "\EA0F";
    --icon-clock-only-v2: "\EA10";
    --icon-clock-only: "\EA11";
    --icon-close: "\EA12";
    --icon-deaktiviert: "\EA13";
    --icon-delete: "\EA14";
    --icon-doppelbett: "\EA15";
    --icon-edit: "\EA16";
    --icon-einzelbett: "\EA17";
    --icon-etagenbett: "\EA18";
    --icon-expose: "\EA19";
    --icon-facebook: "\EA1A";
    --icon-gplus: "\EA1B";
    --icon-grundrissplan: "\EA1C";
    --icon-handtuch: "\EA1D";
    --icon-haus: "\EA1E";
    --icon-heart-filled: "\EA1F";
    --icon-heart: "\EA20";
    --icon-help: "\EA21";
    --icon-icon-back-w: "\EA22";
    --icon-icon-back: "\EA23";
    --icon-icon-language-w: "\EA24";
    --icon-icon-language: "\EA25";
    --icon-icon-reservierung-outline: "\EA26";
    --icon-icon-reservierungsnummer-outline: "\EA27";
    --icon-icon-reservierungsnummer: "\EA28";
    --icon-icon_attention: "\EA29";
    --icon-icon_confirm: "\EA2A";
    --icon-icon_expand: "\EA2B";
    --icon-icon_facebook: "\EA2C";
    --icon-icon_filter: "\EA2D";
    --icon-icon_gallery: "\EA2E";
    --icon-icon_grundausstattung: "\EA2F";
    --icon-icon_iCal: "\EA30";
    --icon-icon_info: "\EA31";
    --icon-icon_instagram: "\EA32";
    --icon-icon_kostenkontrolle: "\EA33";
    --icon-icon_kueche: "\EA34";
    --icon-icon_linkedin: "\EA35";
    --icon-icon_liste: "\EA36";
    --icon-icon_merkliste: "\EA37";
    --icon-icon_minimize: "\EA38";
    --icon-icon_play: "\EA39";
    --icon-icon_share: "\EA3A";
    --icon-icon_sortieren: "\EA3B";
    --icon-icon_stadt: "\EA3C";
    --icon-info: "\EA3D";
    --icon-instagram: "\EA3E";
    --icon-kamin: "\EA3F";
    --icon-kinderbett: "\EA40";
    --icon-kitchen: "\EA41";
    --icon-klopapier: "\EA42";
    --icon-kzm-clock: "\EA43";
    --icon-kzm-clockv2: "\EA44";
    --icon-lift: "\EA45";
    --icon-mail: "\EA46";
    --icon-map-pin: "\EA47";
    --icon-menu: "\EA48";
    --icon-message-filled: "\EA49";
    --icon-message: "\EA4A";
    --icon-mietvertrag: "\EA4B";
    --icon-minus: "\EA4C";
    --icon-mouse: "\EA4D";
    --icon-objekte-filled: "\EA4E";
    --icon-objekte: "\EA4F";
    --icon-objektnummer: "\EA50";
    --icon-open: "\EA51";
    --icon-password: "\EA52";
    --icon-personenanzahl: "\EA53";
    --icon-phone: "\EA54";
    --icon-plane: "\EA55";
    --icon-play: "\EA56";
    --icon-poi-outline: "\EA57";
    --icon-poi: "\EA58";
    --icon-pool: "\EA59";
    --icon-quotes: "\EA5A";
    --icon-reservierung: "\EA5B";
    --icon-sauna: "\EA5C";
    --icon-search: "\EA5D";
    --icon-senden: "\EA5E";
    --icon-sofabett-1: "\EA5F";
    --icon-sofabett-2: "\EA60";
    --icon-spinner-minus: "\EA61";
    --icon-spinner-plus: "\EA62";
    --icon-supermarket: "\EA63";
    --icon-to-do-list: "\EA64";
    --icon-train: "\EA65";
    --icon-uhr: "\EA66";
    --icon-user-filled: "\EA67";
    --icon-user: "\EA68";
    --icon-usp_clock: "\EA69";
    --icon-usp_employee: "\EA6A";
    --icon-usp_house: "\EA6B";
    --icon-wohnflaeche: "\EA6C";
    --icon-zentrum: "\EA6D";
    --icon-zustellbett: "\EA6E";
    
}