footer{
    padding:1.5rem 0 6rem 0;
    border-top:1.5rem solid #fff;
    @media screen and (min-width: 768px){
        border-top:3rem solid #fff;
        padding:1.5rem 0;
    }
}

footer a.footer-link-with-icon {
    position: relative;
    padding-left:2rem;
    @media screen and (min-width: 768px){
        padding-left:2.5rem;
    }
}

footer a.footer-link-with-icon.footer-link-with-icon:hover,
footer a.footer-link-with-icon.footer-link-with-icon:focus {
    border:0;
}

footer a.footer-link-with-icon>.icon {
    position: absolute;
    font-size: 1.125rem;
    line-height:1px;
    top:.5rem;
    left:0;
    @media screen and (min-width: 768px){
        font-size: 1.25rem;
        top:.5rem;

    }
}

footer a.footer-link-with-icon:hover>span,
footer a.footer-link-with-icon:focus>span,
footer a:not(.btn):hover,
footer a:not(.btn):focus{
    border-bottom:1px solid;
}


@media screen and (max-width: 767px){
    .footer__contact-col {
        margin-bottom: 4rem;
        text-align: center;
    }
    .footer__contact-col:after {
        content:'';
        position: absolute;
        left:50%;
        bottom: -1.875rem;
        width:80px;
        height:1px;
        margin-left:-40px;
        background: #fff;
    }
}