/*Base-Styling for HTML-Elements*/

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
th {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}
@supports (font-variation-settings: "wdth" 315) {
    th {
        font-family: var(--font-default-vf);
    }
}
textarea {
    resize: vertical;
}

label {
    font-weight: var(--font-default-weight);
    margin-bottom: 0;
}
ul,
ol,
dl {
    margin-bottom: 0;
}
dt {
    font-weight: normal;
}

*::-moz-selection {
    color: #ffffff;
    background-color:  var(--color-primary);
}
*::selection {
    color: #ffffff;
    background-color: var(--color-primary);
}
a.pimcore_glossary {
    color: var(--color-primary);
    text-decoration: underline;
}
h2 a.pimcore_glossary {
    color: #323232;
    text-decoration: underline;
}
acronym[title] {
    text-decoration: underline;
}