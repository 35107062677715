.paging-results {
    margin-bottom:1.5rem;

    @media screen and (min-width: 768px) {
        margin-bottom:5rem;
    }
}

.pagination {
    margin:0;
}
.pagination > li,
.page-item {
    display: inline-block;
    margin-left:-1px;
}
.page-link {
    display: inline-block;
    height:42px;
    line-height:42px;
    width:44px;
    text-align: center;
    margin:0;
    padding: 0;
    color: var(--color-default);
    background-color: #fff;
    border: 1px solid #C7C7C7;
    border-radius: 0;
    font-size: calc(15rem / 16);
    @media screen and (min-width: 768px) {
        width:50px;
        font-size:1.125rem;
    }
}
.page-link:hover,
.page-link:focus,
.page-item.active .page-link {
    color:#000;
    text-decoration: none;
    border: 1px solid #C7C7C7;
    background-color: var(--color-light-grey);
}

.pagination > li:first-child .page-link {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.pagination > li:last-child .page-link {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.page-link--icon .icon {
    font-size:.875rem;
    position: relative;
    top:.125rem;
}
.pagination > li:first-child .icon {
    transform: scale(-1,1);
}