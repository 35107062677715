header .navbar-right__item.watchList-link:focus .icon,
header .navbar-right__item.watchList-link:hover .icon {
    color:var(--color-danger);
}
.btn.is-added .icon-heart:before,
header .navbar-right__item.watchList-link.has-items>.icon:before{
    content:var(--icon-heart-filled);
}

header .navbar-right__item.watchList-link.has-items>.icon {
    animation: heartbeat 1s;
}

@keyframes heartbeat
{
    0%
    {
        transform: scale( 1 );
    }
    25%
    {
        transform: scale( 1.33 );
    }
    50%
    {
        transform: scale( 1 );
    }
    75%
    {
        transform: scale( 1.33 );
    }
    100%
    {
        transform: scale( 1 );
    }
}

.watchList-count:empty {
    display: none;
}
header .navbar-right__item .watchList-count {
    position: absolute;
    left:.75rem;
    bottom: .625rem;
    font-size: .75rem;
    color:#000;
    width:1rem;
    height:1rem;
    line-height:1rem;
    text-align: center;
    border-radius: 50%;
    background:var(--color-light-grey);

    /*@media screen and (min-width: 768px) {*/
    /*    bottom: .375rem;*/
    /*}*/
}