/* right col */
.objekt-right-box {
    position: relative;
    padding:1.5rem;
    border-radius: 10px;
    margin-top:-1rem;
    box-shadow: 0 15px 40px 0 rgba(0,0,0,0.15);
    background-color: #fff;
    @media screen and (min-width: 768px) {
        padding:2.5rem;
        margin-top:-50px;
    }
}
.objekt-right-box.objekt-right-box--blank {
    background: transparent;
    box-shadow: none;
    margin-top:0;
}

@media screen and (min-width: 768px) and (min-height:700px) {
    .sticky-box {
        position: sticky;
        right:0;
        top:110px;
    }
}

/* main content */
.objekt-detail-block {
    margin:1.75rem 0;
    @media screen and (min-width: 768px) {
        margin:3rem 0;
    }
}
.objekt-box {
    position: relative;
    border-radius: 10px;
    border: 1px solid #C7C7C7;
}

.objekt-box hr {
    position: relative;
    margin:1rem -1rem;
}

.objekt-box__body {
    padding:1rem 1.125rem;
    @media screen and (min-width: 768px) {
        padding:1rem 2.5rem;
    }
}
.objekt-box__headline-overlay {
    position: relative;
    /*margin:-2rem 0 1rem -.5rem;*/
    margin: -1.6rem 0 1rem 0;
    @media screen and (min-width: 768px) {
        margin:-1.75rem 0 1rem -.5rem;
    }
}
.chat__headline-overlay {
    position: relative;
    margin:-.5rem 0 0 1.25rem;

    @media screen and (min-width: 768px) {
        margin:-.875rem 0 0 1.875rem;
    }
}
.chat__headline-overlay>span,
.objekt-box__headline-overlay>a,
.objekt-box__headline-overlay>span{
    display: inline-block;
    background: #fff;
    padding:0 .5rem;
    position: relative;
}



.objekt-box.objekt-box--collapseable {
    overflow: hidden;
    padding-top:0;
    padding-bottom:0;
}
.objekt-box.objekt-box--collapseable .objekt-box__mainheadline {
    color:#fff;
    position: relative;
    margin:0;
    background-color: var(--color-primary);
}
.objekt-box.objekt-box--collapseable .objekt-box__mainheadline>a {
    display: block;
    padding:1rem 2.5rem 1rem 1.5rem;
    color:#fff;
    position:relative;

    @media screen and (min-width: 992px) {
        padding:1rem 2.5rem;
    }
}
@media screen and (max-width: 991px) {
    .objekt-box.objekt-box--collapseable .objekt-box__mainheadline>a:after {
        content:var(--icon-chevron);
        font-family:iconfont;
        display:block;
        color:#fff;
        position:absolute;
        right:1rem;
        top:50%;
        transform:translateY(-50%) rotate(90deg);
    }
    .objekt-box.objekt-box--collapseable .objekt-box__mainheadline>a[aria-expanded="true"]:after {
        transform:translateY(-50%) rotate(-90deg);
    }


}


.objekt-detail-sticky {
    padding: .75rem;
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    background: #fff;
    border-top: 1px solid #C7C7C7;
    z-index:500;
    transform: translateY(100px);
    transition: all .2s linear;
}
.objekt-detail-sticky.is-visible {
    transform: translateY(0);
}