.floating-label {
    color:#aaa;
    position: absolute;
    left: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    white-space: nowrap;
    /*font-size:.875rem;*/
    font-size:16px;
    z-index: 1;

    @media screen and (max-width: 320px) {
        /*font-size:.625rem;*/
    }

    @media screen and (min-width: 321px) and (max-width: 767px) {
        /*font-size:.75rem;*/
    }
}

.floating-label.floating-label__word-break {
    white-space: normal;
}

.form-control:focus ~ .floating-label,
select.form-control ~ .floating-label,
.form-control.has-value ~ .floating-label,
.form-control.hasDatepicker + .floating-label,
.has-value .floating-label{
    transform: translateY(-1.975rem) translateX(.125rem) scale(.75);
    color:inherit;
    background: #fff;
    padding:0 .25rem;

    @media screen and (min-width: 768px) {
        transform: translateY(-2.15rem) translateX(-.125rem) scale(.75);
    }
}


/* gleiche stylings wie oben. verursachtproblem in firefox wenn in selben selektor */
.form-control:-webkit-autofill ~ .floating-label,
.form-control:-webkit-autofill:focus ~ .floating-label{
    transform: translateY(-1.975rem) translateX(.125rem) scale(.75);
    color:inherit;
    background: #fff;
    padding:0 .25rem;

    @media screen and (min-width: 768px) {
        transform: translateY(-2.15rem) translateX(-.125rem) scale(.75);
    }
}


select.form-control ~ .floating-label {
    background:#fff;
    transform: translateY(-1.975rem) translateX(.125rem) scale(.75);
    @media screen and (min-width: 768px) {
        transform: translateY(-2.15rem) translateX(-.125rem) scale(.75);
    }
}


/* floating label in textarea */
textarea ~ .floating-label__label {
    top:1.25rem;

    @media screen and (min-width: 768px) {
        top: 1.75rem;
    }

    @media screen and (max-width: 767px) {
        left: .75rem;
    }
}
